import { useContext } from 'react';

import { JwtAuthContext } from './JwtContext';
import { GoogleAuthContext } from './GoogleAuthContext';
import { MicrosoftAuthContext } from './providers/MicrosoftAuthContextProvider';

export const useJwtAuthContext = () => {
  const context = useContext(JwtAuthContext);

  if (!context) throw new Error('useJwtAuthContext context must be use inside JwtAuthContext');

  return context;
};

export const useGoogleAuthContext = () => {
  const context = useContext(GoogleAuthContext);

  if (!context)
    throw new Error('useGoogleAuthContext context must be use inside GoogleAuthContextProvider');

  return context;
};

export const useMicrosoftAuthContext = () => {
  const context = useContext(MicrosoftAuthContext);

  if (!context)
    throw new Error(
      'useMicrosoftAuthContext context must be use inside MicrosoftAuthContextProvider'
    );

  return context;
};
