import { SxProps, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSettingsContext } from '../../../components/settings';
import Iconify from '../../../components/iconify';

const toggleSx: (backgroundColor: string) => SxProps = (backgroundColor) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.Mui-selected, &:hover, &.Mui-selected:hover': {
    color: '#fff',
    backgroundColor,
  },
});

export function ThemeSwitch() {
  const { themeMode, onToggleMode } = useSettingsContext();

  return (
    <ToggleButtonGroup
      size="small"
      value={themeMode}
      exclusive
      onChange={onToggleMode}
      aria-label="Theme mode"
      sx={{
        borderWidth: '1px',
        borderRadius: '8px',
        '& .MuiToggleButtonGroup-grouped': {
          margin: 0,
          padding: '8px',
        },
      }}
    >
      <ToggleButton value="light" aria-label="light theme" sx={toggleSx('#f5c12f')}>
        <Iconify icon="teenyicons:sun-solid" />
      </ToggleButton>
      <ToggleButton value="dark" aria-label="dark theme" sx={toggleSx('#147ad6')}>
        <Iconify icon="ri:moon-line" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
