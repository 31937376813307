import { Theme, ThemeProvider, createTheme } from '@mui/material';
import { CustomPalette } from '@mui/material/styles/createPalette';
import { Typography } from '@mui/material/styles/createTypography';
import { useMemo } from 'react';

import { useSettingsContext } from '../../components/settings';
import { customShadows } from '../../theme/customShadows';
import componentsOverride from '../../theme/overrides';
import { LOGIN } from '../../theme/palette';
import { shadows } from '../../theme/shadows';
import { typography as baseTypography, pxToRem } from '../../theme/typography';

export function LoginTheme({ children }: Readonly<{ children: React.ReactNode }>) {
  const { themeDirection } = useSettingsContext();

  const theme: Theme = useMemo(() => {
    const palette = LOGIN;
    const typography = baseTypography(
      'Catamaran, sans-serif',
      'Hind Vadodara, sans-serif',
      'Hind Vadodara, sans-serif',
      'Catamaran, sans-serif'
    ) as Typography;
    typography.fontWeightRegular = 300;
    typography.fontWeightBold = 600;
    typography.body1.fontSize = pxToRem(18);
    typography.body2.fontSize = pxToRem(16);
    typography.subtitle2.fontWeight = 300;
    typography.button.fontWeight = 500;
    typography.button.fontFamily = 'Catamaran, sans-serif';
    typography.caption.fontSize = '1.125rem';
    typography.caption.fontWeight = 300;

    const theme = createTheme({
      palette,
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(palette),
    });
    (theme.palette as CustomPalette).mode = 'login';
    theme.customShadows = customShadows(theme.palette);
    theme.components = componentsOverride(theme);
    return theme;
  }, [themeDirection]);

  return (
    <>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
}
