// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, BoxProps, Link, Stack } from '@mui/material';
import { Trans } from 'react-i18next';
// config
import { HEADER } from '../../config-global';
// utils
import { bgBlur } from '../../utils/cssStyles';
// routes
// components
import Logo from '../../components/logo';
import { ThemeSwitch } from '../dashboard/header/ThemeSwitch';
import { LanguagePopover } from '../dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

type Props = {
  isOffset: boolean;
};

export default function Header({ isOffset }: Props) {
  const theme = useTheme();

  return (
    <AppBar color="primary" sx={{ boxShadow: 0 }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Logo horizontal />

        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <ThemeSwitch />
          <LanguagePopover sx={{ color: 'primary.main', backgroundColor: 'background.default' }} />
          <Link href="mailto:suporte@ainext.com.br" variant="subtitle2" color="inherit">
            <Trans i18nKey="login.NeedHelp">Need help?</Trans> suporte@ainext.com.br
          </Link>
        </Stack>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
