// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/console';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  completeSingup: path(ROOTS_AUTH, '/complete-singup'),
  confirmSocialAuth: path(ROOTS_AUTH, '/confirm-social-auth'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  firstAcess: '/first-access',
  userSelfProfile: '/user/profile',
  userProfile: (id: string) => `/user/profile/${id}`,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: {
    root: path(ROOTS_DASHBOARD, '/home'),
  },
  dashboard: {
    root: path(ROOTS_DASHBOARD, '/dashboard'),
    dynamic: path(ROOTS_DASHBOARD, '/dashboard/dynamic'),
  },
  endpoints: {
    root: path(ROOTS_DASHBOARD, '/endpoints'),
  },
  integrations: {
    root: path(ROOTS_DASHBOARD, '/integrations'),
  },
  Audit: {
    root: path(ROOTS_DASHBOARD, '/vulnerabilities'),
  },
  alerts: {
    root: path(ROOTS_DASHBOARD, '/alerts'),
    details: ({ alertId, contextId }: { alertId: string; contextId: string }) =>
      `${path(ROOTS_DASHBOARD, '/alerts')}/${contextId}/${alertId}`,
  },
  playbook: {
    root: path(ROOTS_DASHBOARD, '/playbook'),
    create: path(ROOTS_DASHBOARD, '/playbook/create'),
  },
  contextManagement: {
    domain: path(ROOTS_DASHBOARD, '/management/domain'),
    context: (organizationId?: number) =>
      path(
        ROOTS_DASHBOARD,
        organizationId ? `/management/context/${organizationId}` : `/management/context`
      ),
  },
  logs: {
    root: path(ROOTS_DASHBOARD, '/logs'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
