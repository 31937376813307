import { createContext, useReducer, useCallback, useMemo } from 'react';

import axios, { isLoggedIn } from '../../utils/axios';

import {
  ActionMapType,
  PlaybooksStateType,
  PlaybooksType,
  PlaybookContextType,
} from './PlaybookContextType';

enum Types {
  INITIAL = 'INITIAL',
  GETPLAYBOOK = 'GETPLAYBOOK',
  GETPLAYBOOKS = 'GETPLAYBOOKS',
  GETRULES = 'GETRULES',
  GETSOURCES = 'GETSOURCES',
  GETSOURCETYPES = 'GETSOURCETYPES',
  GETSOURCETYPESFIELDS = 'GETSOURCETYPESFIELDS',
}

type PayLoad = {
  [Types.INITIAL]: {
    isInitialized: boolean;
    playbooks: PlaybooksType;
  };
  [Types.GETPLAYBOOK]: {
    playbook: PlaybooksType;
  };
  [Types.GETPLAYBOOKS]: {
    playbooks: PlaybooksType;
  };
  [Types.GETRULES]: {
    rules: PlaybooksType;
  };
  [Types.GETSOURCES]: {
    sources: PlaybooksType;
  };
  [Types.GETSOURCETYPES]: {
    sourceTypes: PlaybooksType;
  };
  [Types.GETSOURCETYPESFIELDS]: {
    sourceTypesFields: PlaybooksType;
  };
};

type ActionsType = ActionMapType<PayLoad>[keyof ActionMapType<PayLoad>];

const initialState: PlaybooksStateType = {
  isInitialized: false,
  sources: null,
  sourceTypes: null,
  sourceTypesFields: null,
  playbooks: null,
  currentPlaybook: null,
  rules: null,
};

const reducer = (state: PlaybooksStateType, action: ActionsType): PlaybooksStateType => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        ...state,
        isInitialized: action.payload.isInitialized,
        playbooks: action.payload.playbooks,
      };
    case Types.GETPLAYBOOK:
      return {
        ...state,
        currentPlaybook: action.payload.playbook,
      };
    case Types.GETPLAYBOOKS:
      return {
        ...state,
        playbooks: action.payload.playbooks,
      };
    case Types.GETRULES:
      return {
        ...state,
        rules: action.payload.rules,
      };
    case Types.GETSOURCES:
      return {
        ...state,
        sources: action.payload.sources,
      };
    case Types.GETSOURCETYPES:
      return {
        ...state,
        sourceTypes: action.payload.sourceTypes,
      };
    case Types.GETSOURCETYPESFIELDS:
      return {
        ...state,
        sourceTypesFields: action.payload.sourceTypesFields,
      };
    default:
      return state;
  }
};

export const PlaybookContext = createContext<PlaybookContextType | null>(null);

type ContextProviderProps = {
  children: React.ReactNode;
};

export function PlaybooksProvider({ children }: ContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getPlaybook = useCallback(async (id: string, context: string) => {
    try {
      if (!isLoggedIn()) {
        throw new Error('No access token found');
      }

      const response = await axios.get(`/api/playbook`, {
        params: {
          id,
          context,
        },
      });

      dispatch({
        type: Types.GETPLAYBOOK,
        payload: {
          playbook: response.data,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getPlaybooks = useCallback(async (context: string[]) => {
    try {
      if (!isLoggedIn()) {
        throw new Error('No access token found');
      }

      const response = await axios.get('/api/playbook/list', {
        params: {
          contexts: context.join(','),
        },
      });
      dispatch({
        type: Types.GETPLAYBOOKS,
        payload: {
          playbooks: response.data.Playbooks,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getRules = useCallback(async (id: string[]) => {
    try {
      if (!isLoggedIn()) {
        throw new Error('No access token found');
      }

      const response = await axios.get('/api/playbook/rules', {
        params: {
          id,
        },
      });

      dispatch({
        type: Types.GETRULES,
        payload: {
          rules: response.data,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getSources = useCallback(async (contexts: string[]) => {
    try {
      if (!isLoggedIn()) {
        throw new Error('No access token found');
      }

      const response = await axios.get('/api/playbook/get-sources', {
        params: {
          contexts: contexts.join(','),
        },
      });

      dispatch({
        type: Types.GETSOURCES,
        payload: {
          sources: response.data,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getSourcesTypes = useCallback(async (contexts: string[], index: string, source: string) => {
    try {
      if (!isLoggedIn()) {
        throw new Error('No access token found');
      }

      const response = await axios.get('/api/playbook/get-source-types', {
        params: {
          contexts: contexts.join(','),
          index,
          source,
        },
      });

      dispatch({
        type: Types.GETSOURCETYPES,
        payload: {
          sourceTypes: response.data,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getSourcesTypesFields = useCallback(
    async (contexts: string[], index: string, source: string, type: string) => {
      try {
        if (!isLoggedIn()) {
          throw new Error('No access token found');
        }

        const response = await axios.get('/api/playbook/get-source-types-fields', {
          params: {
            contexts: contexts.join(','),
            index,
            source,
            source_type: type,
          },
        });

        dispatch({
          type: Types.GETSOURCETYPESFIELDS,
          payload: {
            sourceTypesFields: response.data,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const memorizedvalue = useMemo(
    () => ({
      getPlaybook,
      getPlaybooks,
      getRules,
      getSources,
      getSourcesTypes,
      getSourcesTypesFields,
      playbooks: state.playbooks,
      currentPlaybook: state.currentPlaybook,
      rules: state.rules,
      sources: state.sources,
      sourceTypes: state.sourceTypes,
      sourceTypesFields: state.sourceTypesFields,
    }),
    [
      state.playbooks,
      state.currentPlaybook,
      state.rules,
      state.sources,
      state.sourceTypes,
      state.sourceTypesFields,
      getPlaybook,
      getPlaybooks,
      getRules,
      getSources,
      getSourcesTypes,
      getSourcesTypesFields,
    ]
  );

  return <PlaybookContext.Provider value={memorizedvalue}>{children}</PlaybookContext.Provider>;
}
