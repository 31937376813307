import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserEntity } from '../../@types/user';
import { InGetUserById, PatchUser, PatchUserError, userWireToInternal } from '../../auth/UserApi';
import { useJwtAuthContext } from '../../auth/useAuthContext';
import axiosInstance from '../../utils/axios';
import { GenericErrorResponse, GenericSuccessResponse, wrapAxios } from '../../utils/axios-api';
import { Result, resultAsync } from '../../utils/result';
import { AxiosResponse } from 'axios';

interface UsersContextState {
  currentUser?: UserEntity;
  userList: Record<number, UserEntity>;
}

interface UsersContextValue extends UsersContextState {
  getCurrentUser: () => Promise<Result<UserEntity | undefined, Error>>;
  getUserById: (userId?: number) => Promise<UserEntity | undefined>;
  uploadPhoto: (photo: File) => Promise<Result<true, Error>>;
  updateUser: (user: Pick<UserEntity, 'id'> & PatchUser) => Promise<Result<true, Error>>;
}

export const UsersContext = createContext<UsersContextValue | undefined>(undefined);

export function useUsersContext() {
  const context = useContext(UsersContext);
  if (!context) {
    throw new Error('useUsersContext for UsersContext must be use inside UsersContextProvider');
  }
  return context;
}

export async function GETUserByID(userId: number) {
  if (!axiosInstance.defaults.headers.common.Authorization) {
    throw new Error('No Authorization header');
  }
  const userResponse = await resultAsync<AxiosResponse<InGetUserById>, GenericErrorResponse>(
    axiosInstance.get(`/v1/users/${userId}`)
  );

  if (!userResponse.ok) {
    console.error(userResponse);
    return;
  }

  const userInternal = userWireToInternal(userResponse.ok.data.user);
  if (!userInternal.ok) {
    console.error(userInternal);
    return;
  }
  return userInternal.ok;
}
const userRequestsList: Record<number, ReturnType<typeof GETUserByID>> = {};

export function UsersContextProvider({ children }: { children: React.ReactNode }) {
  const { user, getUser } = useJwtAuthContext();
  const [currentUser, setCurrentUser] = useState<UsersContextState['currentUser']>(user);
  const [userList, setUserList] = useState<UsersContextState['userList']>({});

  useEffect(() => {
    setCurrentUser(user);
    if (user && !userList[user.id]) {
      setUserList({ ...userList, [user.id]: user });
    }
  }, [user, getUser, userList]);

  const getCurrentUser: UsersContextValue['getCurrentUser'] = useCallback(async () => {
    setCurrentUser(undefined);
    const user = await getUser();
    if (user.ok) {
      setCurrentUser(user.ok);
    }
    return user;
  }, [getUser]);

  const getUserById: UsersContextValue['getUserById'] = useCallback(
    async (userId) => {
      if (!userId) {
        return undefined;
      }
      if (userList[userId] === undefined && userRequestsList[userId] === undefined) {
        userRequestsList[userId] = GETUserByID(userId);
      }
      const result = await userRequestsList[userId];
      if (result) {
        setUserList({ ...userList, [userId]: result });
      }
      return result;
    },
    [userList]
  );

  const uploadPhoto: UsersContextValue['uploadPhoto'] = useCallback(async (photo) => {
    const response = await resultAsync<
      AxiosResponse<GenericSuccessResponse, {}>,
      GenericErrorResponse
    >(axiosInstance.putForm(`/v1/users/photo`, { photo }));
    if (!response.ok) {
      console.error(response);
      return { error: new Error(response.error?.message || 'Failed to PUT /v1/users/photo') };
    }
    return { ok: true } as const;
  }, []);

  const updateUser: UsersContextValue['updateUser'] = useCallback(async (user) => {
    const response = await wrapAxios<PatchUser, GenericSuccessResponse, PatchUserError>(
      axiosInstance.patch
    )(`/v1/users/${user.id}`, {
      ...(user.name ? { name: user.name } : {}),
      ...(user.email ? { email: user.email } : {}),
      ...(user.password ? { password: user.password } : {}),
      ...(user.phone ? { phone: user.phone } : {}),
    });
    if (!response.ok) {
      console.error(response);
      return { error: new Error(response.error?.message || 'Failed to PATCH /v1/users/:id') };
    }
    return { ok: true } as const;
  }, []);

  const value: UsersContextValue = useMemo(
    () => ({
      currentUser,
      getCurrentUser,
      userList,
      getUserById,
      uploadPhoto,
      updateUser,
    }),
    [currentUser, getCurrentUser, userList, getUserById, uploadPhoto, updateUser]
  );
  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
}
