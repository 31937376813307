// @mui
import { List, Stack, useTheme } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
import { FEATURES } from '../../../config-global';
import { ThemeSwitch } from '../../../layouts/dashboard/header/ThemeSwitch';
import { LanguagePopover } from '../../../layouts/dashboard/header/LanguagePopover';
import { NotificationsPopover } from '../../../layouts/dashboard/header/NotificationsPopover';
//
import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';
import NavList from './NavList';

export function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const showThemeToggle = FEATURES.DARK_MODE || theme.palette.mode === 'dark';

  return (
    <Stack sx={sx} {...other}>
      <Stack direction="row" justifyContent="space-evenly">
        {showThemeToggle && <ThemeSwitch />}
        <LanguagePopover sx={{ color: 'primary.main', backgroundColor: 'background.default' }} />
        <NotificationsPopover sx={{ color: isDark ? 'primary.main' : 'primary.contrastText' }} />
      </Stack>

      {data.map((group) => {
        const key = group.subheader || group.items[0].title;
        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
