import { useState, useEffect, Dispatch, SetStateAction } from 'react';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';

// ----------------------------------------------------------------------

/**
 * State backed by local storage
 *
 * @param {string} key key on local storage
 * @param {ValueType} defaultValue value to be used as state if no storage is found
 * @returns reactive state and setState
 */
export default function useLocalStorage<ValueType>(
  key: string,
  defaultValue: ValueType
): [ValueType, Dispatch<SetStateAction<ValueType>>] {
  const storageAvailable = localStorageAvailable();

  const [value, setValue] = useState<ValueType>(() => {
    const storedValue = storageAvailable ? localStorage.getItem(key) : null;

    return storedValue === null ? defaultValue : JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage: Dispatch<SetStateAction<ValueType>> = (newValue) => {
    setValue((currentValue: ValueType) => {
      const result =
        typeof newValue === 'function'
          ? (newValue as (prevState: ValueType) => ValueType)(currentValue)
          : newValue;

      if (storageAvailable) {
        if (!result) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(result));
        }
      }

      return result;
    });
  };

  return [value, setValueInLocalStorage];
}
