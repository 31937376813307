import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Button, ButtonProps, MenuItem, Stack, Typography } from '@mui/material';

import { useLocales } from '../../../locales';
import MenuPopover from '../../../components/menu-popover';

export interface LanguagePopoverProps {
  sx?: ButtonProps['sx'];
}

export function LanguagePopover({ sx }: LanguagePopoverProps) {
  const { allLangs, currentLang, onChangeLang } = useLocales();
  const { translate } = useLocales();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <>
      <Button
        endIcon={openPopover ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleOpenPopover}
        variant="soft"
        color="inherit"
        sx={{
          whiteSpace: 'nowrap',
          padding: '0 2rex',
          minHeight: 40,
          textTransform: 'none',
          ...sx,
        }}
      >
        <Typography variant="button" sx={{ textTransform: 'none' }}>
          {currentLang?.value ?? translate('login.selectLanguage')}
        </Typography>
      </Button>
      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 120 }}>
        <Stack spacing={0.5}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
              sx={{
                color: 'text.primary',
                '&.Mui-selected, &:hover': {
                  fontWeight: 'medium',
                  color: 'primary.main',
                  backgroundColor: 'secondary.lighter',
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
