import { useContext } from 'react';
//
import { ContextProvider } from './ContextProvider';

// ----------------------------------------------------------------------

export const useContextProvider = () => {
  const context = useContext(ContextProvider);

  if (!context)
    throw new Error('useContextProvider context must be use inside ContextProvider');

  return context;
};
