import { AxiosError, AxiosResponse } from 'axios';
import { createContext, useContext, useMemo, useState } from 'react';
import { Outlet } from 'react-router';
import {
  Alert,
  AlertComment,
  AlertCommentWireOut,
  AlertKPI,
  AlertKPIWire,
  AlertWire,
  alertKPIWireToInternal,
  alertWireToInternal,
} from '../../@types/alert';
import { useJwtAuthContext } from '../../auth/useAuthContext';
import { useContextProvider } from '../../context/useContextProvider';
import axiosInstance from '../../utils/axios';
import { GenericErrorResponse, GenericSuccessResponse } from '../../utils/axios-api';
import { Result, resultAsync } from '../../utils/result';

interface AlertsState {
  alerts?: Alert[];
  kpis?: AlertKPI;
}

interface AlertMethods {
  getAlerts: (alertFilter?: Partial<Alert>) => Promise<Result<Alert[], Error>>;
  /** GET and alert by ID and GET comments */
  getAlert: (alertId: string, contexts: string) => Promise<Result<Alert, Error>>;
  handleAlert: (
    alert: Alert,
    action: 'assigned_to' | 'resolved_by'
  ) => Promise<Result<true, Error>>;
  addComment: (alert: Alert, content: AlertCommentWireOut) => Promise<Result<true, Error>>;
  getKPIs: () => Promise<Result<AlertKPI, Error>>;
}

interface AlertContext extends AlertsState, AlertMethods {}

export const AlertsContext = createContext<AlertContext | null>(null);

export function useAlertsContext() {
  const context = useContext(AlertsContext);
  if (!context) {
    throw new Error('useAlertsContext must be used within a AlertsContextProvider');
  }
  return context;
}

interface Debouncer<T> {
  value?: Map<string, T>;
  lastRun?: Date;
  waitSeconds: number;
}
function debounce<T>(key: string, debouncer: Debouncer<T>, factory: () => T): T {
  if (debouncer.value === undefined) {
    debouncer.value = new Map();
  }
  let value = debouncer.value.get(key);
  if (
    value !== undefined &&
    (debouncer.lastRun?.getTime() ?? 0) + debouncer.waitSeconds * 1_000 > Date.now()
  ) {
    return value;
  }
  value = factory();
  debouncer.value.set(key, value);
  debouncer.lastRun = new Date();
  return value;
}
async function debounceResult<T, R extends Promise<Result<T, Error>>>(
  key: string,
  debouncer: Debouncer<R>,
  factory: () => R
) {
  const result = await debounce<R>(key, debouncer, factory);
  if (!result.ok) {
    debouncer.lastRun = undefined;
  }
  return result;
}

export function AlertsContextProvider({ children }: { children?: React.ReactNode }) {
  const [alerts, setAlerts] = useState<AlertsState['alerts']>();
  const [kpis, setKpis] = useState<AlertsState['kpis']>();
  const { user } = useJwtAuthContext();
  const { selectedContexts } = useContextProvider();

  const methods = useMemo<AlertMethods>(() => {
    const inFlight = new Map<
      string,
      Promise<
        [
          Result<AxiosResponse<AlertWire, {}>, AxiosError<GenericErrorResponse>>,
          Result<AxiosResponse<{ comments: AlertComment[] }, {}>, AxiosError<GenericErrorResponse>>
        ]
      >
    >();
    const kpiDebouncer = { waitSeconds: 60 };
    const alertsDebouncer = { waitSeconds: 60 };

    const memo: AlertMethods = {
      getAlerts: async (alertFilter) => {
        const contexts = selectedContexts?.length
          ? selectedContexts?.map((c) => c.id).join(',')
          : user?.contexts?.join(',');
        if (!contexts || contexts.length === 0) {
          const error = new Error('No User contexts to fetch all alerts');
          console.error(error);
          return { error };
        }
        const key = JSON.stringify(alertFilter);
        return await debounceResult(key, alertsDebouncer, async () => {
          const response = await resultAsync<
            AxiosResponse<AlertWire[] | null, {}>,
            GenericErrorResponse
          >(
            axiosInstance.get('/v1/alerts', {
              params: { ...alertFilter, contexts },
            })
          );
          if (response.ok?.data?.length === undefined) {
            const error = new Error(response.error?.message ?? 'No alerts found');
            console.error(error);
            return { error };
          }
          const alerts = response.ok.data.map((alert) => alertWireToInternal(alert, user));
          setAlerts(alerts);
          return { ok: alerts };
        });
      },

      getAlert: async (alertId, contexts) => {
        let requests = inFlight.get(alertId);
        if (!requests) {
          requests = Promise.all([
            resultAsync<AxiosResponse<AlertWire, {}>, AxiosError<GenericErrorResponse>>(
              axiosInstance.get(`/v1/alerts/${alertId}`, { params: { contexts } })
            ),
            resultAsync<
              AxiosResponse<{ comments: AlertComment[] }, {}>,
              AxiosError<GenericErrorResponse>
            >(axiosInstance.get(`/v1/alerts/${alertId}/comments`, { params: { contexts } })),
          ]);
          inFlight.set(alertId, requests);
        }
        const [alertResponse, commentsResponse] = await requests;
        if (
          !alertResponse ||
          alertResponse.error ||
          !alertResponse.ok?.data ||
          alertResponse.ok?.data.id !== alertId
        ) {
          const status = alertResponse.error?.response?.status
            ? alertResponse.error?.response?.status + ' '
            : '';
          const error = new Error(
            status +
              ((alertResponse?.error as any)?.error ??
                alertResponse?.error?.message ??
                'Error on GET /v1/alerts/:id')
          );
          console.error(error);
          return { error };
        }
        const alert = alertWireToInternal(alertResponse.ok.data, user);
        if (commentsResponse.ok?.data.comments !== undefined) {
          alert.comments = commentsResponse.ok.data.comments;
        } else {
          console.error(commentsResponse.error?.message ?? 'No comments found');
        }
        setAlerts((prev) => [...(prev ?? []).filter((pre) => pre.id !== alert.id), alert]);
        inFlight.delete(alertId);
        return { ok: alert };
      },

      handleAlert: async (alert, action) => {
        if (!alert.id || !user?.id) {
          return { error: new Error('No alert or user found') };
        }
        const uri =
          action === 'assigned_to'
            ? `/v1/alerts/${alert.id}/assign`
            : `/v1/alerts/${alert.id}/close`;
        const response = await resultAsync<
          AxiosResponse<
            { message: 'Alert assigned' | 'Alert closed' },
            { resolved_by?: number; assigned_to?: number }
          >,
          GenericErrorResponse
        >(
          axiosInstance.patch(
            uri,
            { [action]: user.id },
            { params: { contexts: alert.context_id } }
          )
        );
        if (!response.ok?.data.message) {
          const error = new Error(response.error?.message ?? 'No alert found');
          console.error(error);
          return { error };
        }
        return { ok: true };
      },

      addComment: async (alert, content) => {
        if (!user?.contexts) {
          const error = new Error('No User contexts to fetch all alerts');
          console.error(error);
          return { error };
        }
        const response = await resultAsync<
          AxiosResponse<GenericSuccessResponse & { comment_id: string }, AlertCommentWireOut>,
          GenericErrorResponse
        >(
          axiosInstance.post(`/v1/alerts/${alert.id}/comments`, content, {
            params: { contexts: alert.context_id },
          })
        );
        if (!response.ok?.data) {
          const error = new Error(response.error?.message ?? 'Failed to post comment');
          console.error(error);
          return { error };
        }
        console.log(response.ok.data);
        return { ok: true };
      },

      getKPIs: async () => {
        if (user?.contexts === undefined) {
          const error = new Error('No User contexts to fetch alert KPIs');
          console.error(error);
          return { error };
        }
        return await debounce('kpi', kpiDebouncer, async () => {
          const response = await resultAsync<AxiosResponse<AlertKPIWire, {}>, GenericErrorResponse>(
            axiosInstance.get('/v1/alerts/kpis', {
              params: { contexts: user.contexts.join(',') },
            })
          );
          if (!response.ok?.data) {
            const error = new Error(response.error?.message ?? 'No alerts found');
            console.error(error);
            return { error };
          }
          const internal = alertKPIWireToInternal(response.ok.data.alert_kpis);
          setKpis(internal);
          return { ok: internal };
        });
      },
    };
    return memo;
  }, [selectedContexts, user]);

  const memorizedvalue = useMemo<AlertContext>(
    () => ({ ...methods, alerts, kpis }),
    [methods, alerts, kpis]
  );

  return (
    <AlertsContext.Provider value={memorizedvalue}>{children ?? <Outlet />}</AlertsContext.Provider>
  );
}
