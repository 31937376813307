import { createContext, useMemo, useState } from 'react';
import { GoogleOAuthProvider, TokenResponse, useGoogleLogin } from '@react-oauth/google';

import { GOOGLE_API_CLIENT_ID } from '../config-global';
import axios from '../utils/axios';
import { resultAsync } from '../utils/result';
import { SocialUserInfo } from './types';
import { AxiosResponse } from 'axios';

export type GoogleToken = Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>;

export interface GoogleUserinfo {
  /** The user's email address. */
  email?: string;
  /** The user's last name. */
  family_name?: string;
  /** The user's gender. */
  gender?: string;
  /** The user's first name. */
  given_name?: string;
  /** The hosted domain e.g. example.com if the user is Google apps user. */
  hd?: string;
  /** The obfuscated ID of the user. */
  id?: string;
  /** URL of the profile page. */
  link?: string;
  /** The user's preferred locale. */
  locale?: string;
  /** The user's full name. */
  name?: string;
  /** URL of the user's picture image. */
  picture?: string;
  /** Boolean flag which is true if the email address is verified. Always verified because we only return the user's primary email address. */
  verified_email?: boolean;
}

export type GoogleAuthContextType = {
  login: () => void;
  error?: string;
  enabled: boolean;
};
export const GoogleAuthContext = createContext<GoogleAuthContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
  onLoginWithGoogle?: (token: SocialUserInfo) => void;
};

const googleAuthEnabled = GOOGLE_API_CLIENT_ID?.length > 10;
type GoogleOkReponse = Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>;
type GoogleErrorReponse = Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>;

function GoogleAuth({ children, onLoginWithGoogle }: Readonly<AuthProviderProps>) {
  const [error, setError] = useState<string>();

  const loginWithGoogle: GoogleAuthContextType['login'] = useGoogleLogin({
    onSuccess: async (tokenResponse: GoogleOkReponse): Promise<void> => {
      onLoginWithGoogle?.({
        provider: 'google',
        token: tokenResponse.access_token,
        moreInfo: async (previous: SocialUserInfo) => {
          const googleUserInfo = await resultAsync<
            AxiosResponse<GoogleUserinfo, any>,
            GoogleErrorReponse
          >(
            axios.get<GoogleUserinfo>('https://www.googleapis.com/oauth2/v3/userinfo', {
              headers: { Authorization: `Bearer ${previous.token}` },
            })
          );
          if (!(googleUserInfo?.ok?.data.email && googleUserInfo.ok.data.name)) {
            const message = 'Failed to get email address from Google';
            return {
              error: new Error(
                googleUserInfo?.error?.error_description ?? googleUserInfo?.error?.error ?? message
              ),
            };
          }
          const userInfo: Required<SocialUserInfo> = {
            ...previous,
            email: googleUserInfo.ok.data.email,
            name: googleUserInfo.ok.data.name,
            moreInfo: async () => {
              return { ok: userInfo };
            },
          };
          return { ok: userInfo };
        },
      });
    },
    onError: (errorResponse: GoogleErrorReponse) => {
      const errorMessage = errorResponse?.error_description || 'Google Sing In failed';
      setError(errorMessage);
      console.error(errorMessage, errorResponse);
    },
  });

  const context = useMemo<GoogleAuthContextType>(
    () => ({
      login: () => loginWithGoogle?.(),
      error,
      enabled: true,
    }),
    [loginWithGoogle, error]
  );
  return <GoogleAuthContext.Provider value={context}>{children}</GoogleAuthContext.Provider>;
}

function GoogleAuthDisabled({ children }: Readonly<AuthProviderProps>) {
  const context = useMemo<GoogleAuthContextType>(
    () => ({
      login: () => {},
      enabled: false,
    }),
    []
  );
  return <GoogleAuthContext.Provider value={context}>{children}</GoogleAuthContext.Provider>;
}

export function GoogleAuthContextProvider({ children, onLoginWithGoogle }: AuthProviderProps) {
  return googleAuthEnabled ? (
    <GoogleOAuthProvider clientId={GOOGLE_API_CLIENT_ID}>
      <GoogleAuth onLoginWithGoogle={onLoginWithGoogle}>{children}</GoogleAuth>
    </GoogleOAuthProvider>
  ) : (
    <GoogleAuthDisabled>{children}</GoogleAuthDisabled>
  );
}
