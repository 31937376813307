import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
// components
import { useSettingsContext } from '../components/settings';
//
import { DARK, LIGHT } from './palette';
import { typography } from './typography';
import { shadows } from './shadows';
import { customShadows } from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
import { Theme } from '@mui/system';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext();

  const theme: Theme = useMemo(() => {
    const palette = themeMode === 'dark' ? DARK : LIGHT;
    const theme = createTheme({
      palette,
      typography: typography(),
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(palette),
    });
    theme.customShadows = customShadows(theme.palette);
    theme.components = componentsOverride(theme);
    return theme;
  }, [themeDirection, themeMode]);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
