type ResultOk<Ok> = { ok: NonNullable<Ok>; error?: undefined };
type ResultError<Error> = { ok?: undefined; error: NonNullable<Error> };
export type Result<Ok, Error> = ResultOk<Ok> | ResultError<Error>;

export function result<Ok, Error>(fn: () => NonNullable<Ok>): Result<Ok, Error> {
  try {
    const ok = fn();
    return { ok };
  } catch (error) {
    return { error };
  }
}

export async function resultAsync<Ok, Error>(
  promise: Promise<NonNullable<Ok>>
): Promise<Result<Ok, Error>> {
  try {
    const ok = await promise;
    return { ok };
  } catch (error) {
    return { error };
  }
}
