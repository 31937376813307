import axios from '../utils/axios';
import { GenericErrorResponse, GenericSuccessResponse, wrapAxios } from '../utils/axios-api';
import { Result } from '../utils/result';

/**
 * Backend API has:
 *  - POST /v1/manager/organizations/:organization_id/invite   **InviteToOrganization**
 *  - POST /v1/user/invite                                     **UpdateInvite**
 *
 *  - GET    /v1/manager/organizations                          **GetOrganizations**
 *  - GET    /v1/manager/organizations/:organization_id         **GetOrganization**
 *  - POST   /v1/manager/organizations                          **CreateOrganization**
 *  - PATCH  /v1/manager/organizations/:organization_id         **UpdateOrganization**
 *  - POST   /v1/manager/organizations/:organization_id/invite  **InviteToOrganization**
 *  - POST   /v1/manager/organizations/:organization_id/remove  **RemoveFromOrganization**
 *
 * Missing:
 *  - '/v1/manager/contexts/invite',
 *  - '/v1/manager/domains/invite?contexts=1'
 */
export const doc = '';

interface InviteUserToContextInput {
  email: string;
  read_write?: boolean;
}

interface InviteToOrgError {
  status: 400;
  message:
    | 'invalid organization id'
    | 'organization not found'
    | 'default admin policy not found'
    | 'default read policy not found'
    | 'policies not found';
  error?: string;
}

/**
 * POST /v1/manager/organizations/:organization_id/invite   InviteToOrganization
 *
 * @param orgId
 * @param body
 * @returns
 */
export async function POSTOrganizationInvite({
  organizationId,
  ...body
}: InviteUserToContextInput & { organizationId: number }): Promise<Result<boolean, Error>> {
  if (!axios.defaults.headers.common.Authorization) {
    return { error: new Error('No Authorization header') };
  }
  if (!organizationId) {
    return { error: new Error('No Organization ID') };
  }

  const response = await wrapAxios<
    InviteUserToContextInput,
    GenericSuccessResponse,
    InviteToOrgError
  >(axios.post)(`/v1/manager/organizations/${organizationId}/invite?contexts=0`, body);

  if (response.ok?.data?.status !== 200) {
    return { error: new Error(response.error?.message || 'Failed to POST InviteToOrganization') };
  }
  return { ok: true };
}

interface UpdateInviteInput {
  accept: boolean;
}

interface UpdateInviteError {
  status: 400;
  message: '';
  error?: string;
}

/**
 * POST /v1/user/invite                                     UpdateInvite
 *
 * Accepts or rejects an invite for the current user.
 */
export async function POSTUserInvite(body: UpdateInviteInput): Promise<Result<boolean, Error>> {
  if (!axios.defaults.headers.common.Authorization) {
    return { error: new Error('No Authorization header') };
  }

  const response = await wrapAxios<UpdateInviteInput, GenericSuccessResponse, UpdateInviteError>(
    axios.post
  )(`/v1/user/invite`, body);

  if (response.ok?.data?.status !== 200) {
    return { error: new Error(response.error?.message || 'Failed to POST UpdateInvite') };
  }
  return { ok: true };
}

interface OrganizationModel {
  ID: number;
  FirstParentID: number | null;
  FirstParentOrg: OrganizationModel | null;
  SecondParentID: number | null;
  SecondParentOrg: OrganizationModel | null;
  ThirdParentID: number | null;
  ThirdParentOrg: OrganizationModel | null;
  FourthParentID: number | null;
  FourthParentOrg: OrganizationModel | null;
  FifthParentID: number | null;
  FifthParentOrg: OrganizationModel | null;
  mssp: boolean;
  name: string;
  taxid: string;
  country: string;
  state: string;
  city: string;
  street: string;
  postalcode: string;
  number: string;
  complement: string;
  size: string;
  Contexts: ContextModel[] | null;
}
export interface ContextModel {
  ID: number;
  Name: string;
  Description: string;
  OrganizationID: number;
  Organization: OrganizationModel;
  Credits: number;
}
/**
 * ```go
 * type OrganizationListItem struct {
 * 	ID   uint   `json:"id"`
 * 	Name string `json:"name"`
 * 	Mssp bool   `json:"mssp"`
 * }
 * type ContextListItem struct {
 * 	ID             uint                  `json:"id"`
 * 	Name           string                `json:"name"`
 * 	Description    string                `json:"description"`
 * 	OrganizationID uint                  `json:"organization_id"`
 * 	Organization   *OrganizationListItem `json:"organization"`
 * }
 * ```
 * https://github.com/AINEXTECH/myrmex-core/blob/f64f7e9d4a6f4646ccc4eead1e6cccf022dfdf3b/pkg/handlers/contexts.go#L120
 */
export interface ContextsItemRespnse {
  id: number;
  name: string;
  description: string;
  organization_id: number;
  organization: {
    id: number;
    name: string;
    mssp: boolean;
  };
}
export interface ContextsItem {
  id: number;
  name: string;
  description: string;
  organization: {
    id: number;
    name: string;
    mssp: boolean;
  };
}
export function isContextModel(item: ContextModel | ContextsItemRespnse): item is ContextModel {
  const context = item as ContextModel;
  return (
    context.ID !== undefined &&
    context.Name !== undefined &&
    context.Description !== undefined &&
    context.OrganizationID !== undefined &&
    context.Organization !== undefined
  );
}
export function isContextsItemRespnse(
  item: ContextModel | ContextsItemRespnse
): item is ContextsItemRespnse {
  const context = item as ContextsItemRespnse;
  return (
    context.id !== undefined &&
    context.name !== undefined &&
    context.description !== undefined &&
    context.organization_id !== undefined &&
    context.organization !== undefined &&
    context.organization.id !== undefined &&
    context.organization.name !== undefined &&
    context.organization.mssp !== undefined
  );
}

export interface GetContextsSuccess extends GenericSuccessResponse {
  // @deprecated to be removed
  contexts?: ContextModel[];
  // follows convention https://ainextbr.atlassian.net/wiki/spaces/Arquitetur/pages/72286217/Estrutura+de+Retorno#Exemplo-de-Estrutura%3A
  data?: {
    contexts: ContextsItemRespnse[];
  };
}

interface ManagerOverview {
  alerts: number;
  devices: number;
  integrations: number;
}

export interface HomeTotalStats {
  currentAmount: number;
  lastPeriodAmount?: number;
  period?: string;
}

export interface HomeTotals {
  devices: HomeTotalStats;
  integrations: HomeTotalStats;
  alerts: HomeTotalStats;
}

/**
 * GET /v1/manager/overview
 */

export async function GETManagerOverview(contexts: number[]): Promise<Result<HomeTotals, Error>> {
  if (!axios.defaults.headers.common.Authorization) {
    return { error: new Error('No Authorization header') };
  }

  const response = await wrapAxios<{}, ManagerOverview, GenericErrorResponse>(axios.get)(
    '/v1/manager/overview',
    {
      params: {
        contexts: contexts.join(','),
      },
    }
  );

  if (response?.ok?.data?.alerts === undefined) {
    return { error: new Error(response?.error?.message || 'Failed to GET /v1/manager/overview') };
  }

  const { alerts, devices, integrations } = response.ok.data;
  return {
    ok: {
      alerts: { currentAmount: alerts },
      devices: { currentAmount: devices },
      integrations: { currentAmount: integrations },
    },
  };
}
