import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { clearSession } from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, JWTContextType, SocialUserInfo, User } from './types';
import { GoogleAuthContextProvider } from './GoogleAuthContext';
import { MicrosoftAuthContextProvider } from './providers/MicrosoftAuthContextProvider';
import {
  ConfirmSocialLoginInput,
  ConfirmSocialLoginSuccess,
  CreateUserError,
  CreateUserInput,
  CreateUserSuccess,
  GetUserSessionError,
  GetUserSessionSuccess,
  InGetUserById,
  InOrganization,
  LoginError,
  LoginInput,
  LoginSuccess,
  UpdateInviteInput,
  userWireToInternal,
} from './UserApi';
import { GenericErrorResponse, GenericSuccessResponse } from '../utils/axios-api';
import {
  ContextsItem,
  GetContextsSuccess,
  isContextModel,
  isContextsItemRespnse,
} from '../context/ContextApi';
import { Session, UserEntity } from '../@types/user';
import { Result, resultAsync } from '../utils/result';
import { AxiosResponse } from 'axios';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// - REDUCER
// ----------------------------------------------------------------------
enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  GET_USER = 'GET_USER',
  SOCIAL_AUTH = 'SOCIAL_AUTH',
  SET_PROFILE_COMPLETION_NEEDED = 'SET_IS_PROFILE_COMPLETE',
  SET_SOCIAL_AUTH_CONFIRMATION_NEEDED = 'SET_SOCIAL_AUTH_CONFIRMATION_NEEDED',
  SET_SOCIAL_AUTH_ADD_USER_EDIT = 'SET_SOCIAL_AUTH_ADD_USER_EDIT',
}

type Payload = {
  [Types.INITIAL]: Pick<AuthStateType, 'isAuthenticated' | 'user'>;
  [Types.LOGIN]: Required<Pick<AuthStateType, 'user'>>;
  [Types.REGISTER]: Required<Pick<AuthStateType, 'user'>>;
  [Types.LOGOUT]: undefined;
  [Types.GET_USER]: Required<Pick<AuthStateType, 'user'>>;
  [Types.SOCIAL_AUTH]: AuthStateType['socialUserInfo'];
  [Types.SET_PROFILE_COMPLETION_NEEDED]: boolean;
  [Types.SET_SOCIAL_AUTH_CONFIRMATION_NEEDED]: boolean;
  [Types.SET_SOCIAL_AUTH_ADD_USER_EDIT]: boolean;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
};

const reducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
      };
    case Types.LOGIN:
    case Types.REGISTER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case Types.LOGOUT:
      return {
        isInitialized: true,
        isAuthenticated: false,
      };
    case Types.GET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case Types.SOCIAL_AUTH:
      return {
        ...state,
        socialUserInfo: action.payload,
      };
    case Types.SET_PROFILE_COMPLETION_NEEDED:
      return {
        ...state,
        profileComplionNeeded: action.payload,
      };
    case Types.SET_SOCIAL_AUTH_CONFIRMATION_NEEDED:
      return {
        ...state,
        socialAuthConfirmationNeeded: action.payload,
      };
    case Types.SET_SOCIAL_AUTH_ADD_USER_EDIT:
      return {
        ...state,
        socialAuthAddUserEdit: action.payload,
      };
  }
  function exhaustiveGuard(_value: never): never {
    throw new Error(
      `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`
    );
  }
  exhaustiveGuard(action);
  return state;
};

// ----------------------------------------------------------------------

export const JwtAuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

namespace api {
  async function getUserEntity(session: Session): Promise<Result<UserEntity, Error>> {
    const userResponse = await resultAsync<AxiosResponse<InGetUserById, {}>, GenericErrorResponse>(
      axios.get(`/v1/users/${session.user_id}`)
    );
    if (!userResponse.ok?.data?.user) {
      return {
        error: new Error(
          userResponse.error?.message || `Failed to GET /v1/users/${session.user_id}`
        ),
      };
    }

    const organizationResponse = await resultAsync<
      AxiosResponse<InOrganization, {}>,
      GenericErrorResponse
    >(
      axios.get(`/v1/manager/organizations/${userResponse.ok.data.user.OrganizationID}`, {
        params: { contexts: session.contexts.join(',') },
      })
    );
    if (organizationResponse.ok?.data?.ID === userResponse.ok.data.user.OrganizationID) {
      userResponse.ok.data.user.Organization = organizationResponse.ok.data;
    }

    const userInternal = userWireToInternal(userResponse.ok.data.user);
    if (!userInternal.ok?.id) {
      return {
        error: new Error(
          userInternal.error?.message || `Failed convert response to internal user entity`
        ),
      };
    }

    return { ok: userInternal.ok };
  }

  async function getUserContexts(session: Session): Promise<Result<ContextsItem[], Error>> {
    const contextResponse = await resultAsync<
      AxiosResponse<GetContextsSuccess, {}>,
      GenericErrorResponse
    >(
      axios.get('/v1/manager/contexts', {
        params: { contexts: session.contexts.join(',') },
        // timeout: 3_000,
      })
    );
    if (!contextResponse.ok) {
      return {
        error: new Error(
          contextResponse.error?.message ?? contextResponse.error ?? 'Error fetching contexts'
        ),
      };
    }
    if (
      Array.isArray(contextResponse.ok.data.data?.contexts) &&
      contextResponse.ok.data.data?.contexts.some(isContextsItemRespnse)
    ) {
      return {
        ok: contextResponse.ok.data.data?.contexts.map<ContextsItem>((context) => ({
          ...context,
          organizationId: context.organization_id,
        })),
      };
    }
    if (
      Array.isArray(contextResponse.ok.data.contexts) &&
      contextResponse.ok.data.contexts.some(isContextModel)
    ) {
      const contexts = contextResponse.ok.data.contexts.map<ContextsItem>((context) => ({
        id: context.ID,
        name: context.Name,
        description: context.Description,
        organizationId: context.OrganizationID,
        organization: {
          id: context.Organization.ID,
          name: context.Organization.name,
          mssp: context.Organization.mssp,
        },
      }));
      return { ok: contexts };
    }
    return { error: new Error('Failed to get contexts') };
  }

  async function _getAxiosUserSession(): Promise<Result<User, Error>> {
    if (!axios.defaults.headers.common.Authorization) {
      return { error: new Error('No Authorization header') };
    }

    const sessionResponse = await resultAsync<
      AxiosResponse<GetUserSessionSuccess>,
      GetUserSessionError
    >(axios.get('/v1/user'));
    if (!sessionResponse.ok) {
      return { error: new Error(sessionResponse.error?.message || 'Failed to GET /v1/user') };
    }

    const contexts = sessionResponse.ok.data.contexts.sort();
    const session: Session = {
      ...sessionResponse.ok.data,
      contexts,
    };

    const [user, userContexts] = await Promise.all([
      getUserEntity(session),
      getUserContexts(session),
    ]);
    if (user.ok?.id === undefined) {
      return { error: user.error ?? new Error('Failed to get user entity') };
    }
    if (userContexts.ok?.length === undefined) {
      // return { error: userContexts.error ?? new Error('Failed to get user contexts') };
      console.log('Failed to get user contexts', userContexts.error);
    }

    return { ok: { ...session, ...user.ok, userContexts: userContexts.ok } };
  }

  let inFlightRequest: Promise<Result<User, Error>> | undefined;
  export async function getAxiosUserSession(): Promise<Result<User, Error>> {
    if (inFlightRequest) {
      return inFlightRequest;
    }
    inFlightRequest = _getAxiosUserSession();
    const result = await inFlightRequest;
    inFlightRequest = undefined;
    return result;
  }
}

export function JwtAuthProvider({ children }: Readonly<AuthProviderProps>) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const refreshToken = storageAvailable ? localStorage.getItem('refreshToken') : '';

      if (isValidToken(accessToken)) {
        setSession(accessToken, refreshToken);

        const userResponse = await api.getAxiosUserSession();
        if (!userResponse.ok?.email) {
          throw userResponse.error || new Error('Error getting user session info.');
        }
        const user = userResponse.ok;
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: user.email !== null,
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: { isAuthenticated: false },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: { isAuthenticated: false },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const usernamePasswordlogin: JWTContextType['usernamePasswordlogin'] = useCallback(
    async (email: string, password: string) => {
      const result = await resultAsync<AxiosResponse<LoginSuccess, LoginInput>, LoginError>(
        axios.post('/v1/auth/login', { username: email, password })
      );
      if (!result?.ok?.data?.access_token) {
        const message =
          result?.error?.error ??
          result?.error?.message ??
          `Failed to reach Myrmex API on POST /v1/auth/login. You may try again.`;
        throw new Error(message ?? 'Error logging in.');
      }
      const { access_token, refresh_token } = result.ok.data;

      setSession(access_token, refresh_token);

      const userResponse = await api.getAxiosUserSession();
      if (!userResponse.ok) {
        throw userResponse.error || new Error('Error getting user session info.');
      }
      const user = userResponse.ok;

      dispatch({
        type: Types.LOGIN,
        payload: { user },
      });
    },
    []
  );

  const refreshToken: JWTContextType['refreshToken'] = useCallback(async () => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      return;
    }
    try {
      const response = await axios.post('/v1/auth/refresh', {
        refresh_token: refreshToken,
      });

      const { access_token, refresh_token } = response.data;

      setSession(access_token, refresh_token);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // REGISTER
  const register: JWTContextType['register'] = useCallback(async (user: CreateUserInput) => {
    const response = await resultAsync<
      AxiosResponse<CreateUserSuccess, CreateUserInput>,
      CreateUserError
    >(axios.post(`/v1/users`, user));
    let result;
    if (response?.ok?.data?.username) {
      result = {
        ok: {
          email: response?.ok?.data?.username,
        },
      };
    } else {
      result = {
        error: new Error(response?.error?.message ?? response.error?.error ?? 'POST /v1/users/'),
      };
    }

    if (result?.ok) {
      dispatch({
        type: Types.SET_PROFILE_COMPLETION_NEEDED,
        payload: false,
      });
    }
    return result;

    // Comentado pra não logar automaticamente, mas no futuro da pra voltar, e pedir pro usuario autenticar direto da home page.s

    // localStorage.setItem('accessToken', accessToken);

    // dispatch({
    //   type: Types.REGISTER,
    //   payload: {
    //     user,
    //   },
    // });
  }, []);

  // LOGOUT
  const logout: JWTContextType['logout'] = useCallback(async () => {
    setSession(null, null);
    clearSession();
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const confirmEmail: JWTContextType['confirmEmail'] = useCallback(
    async (token: string): Promise<void> => {
      const config = {
        params: {
          token,
        },
      };

      const response = await axios.post('/v1/user/confirm-email', {}, config);

      const { access_token, refresh_token } = response.data;

      setSession(access_token, refresh_token);

      const userResponse = await api.getAxiosUserSession();
      if (!userResponse.ok) {
        throw userResponse.error || new Error('Error getting user session info.');
      }
      const user = userResponse.ok;
      dispatch({
        type: Types.LOGIN,
        payload: { user },
      });
    },
    []
  );

  const confirmEmailSocial: JWTContextType['confirmEmailSocial'] = useCallback(
    async (token: string): Promise<void> => {
      const config = {
        params: {
          token,
        },
      };

      await axios.post('/v1/user/confirm-email', {}, config);
      const userResponse = await api.getAxiosUserSession();
      if (!userResponse.ok) {
        throw userResponse.error || new Error('Error getting user session info.');
      }
      const user = userResponse.ok;
      dispatch({
        type: Types.LOGIN,
        payload: { user },
      });
    },
    []
  );

  const resendConfirmEmail: JWTContextType['resendConfirmEmail'] = useCallback(
    async (email: string): Promise<void> => {
      await axios.post('/v1/user/resend-confirmation', { email });
    },
    []
  );

  const sendResetPasswordEmail: JWTContextType['sendResetPasswordEmail'] = useCallback(
    async (email: string): Promise<void> => {
      await axios.post('/v1/user/reset-password', { email });
    },
    []
  );

  const confirmResetPassword: JWTContextType['confirmResetPassword'] = useCallback(
    async (password: string): Promise<void> => {
      const storageAvailable = localStorageAvailable();
      const accessToken = storageAvailable ? localStorage.getItem('accessTokenResetPassword') : '';
      if (!accessToken) {
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios.post('/v1/user/confirm-reset-password', { password }, config);
      localStorage.removeItem('accessTokenResetPassword');

      await new Promise((resolve) => setTimeout(resolve, 5000));

      const email = sessionStorage.getItem('ResendStorage');
      const response = await axios.post('/v1/auth/login', {
        username: email,
        password,
      });
      const { access_token, refresh_token } = response.data;

      setSession(access_token, refresh_token);

      const userResponse = await api.getAxiosUserSession();
      if (!userResponse.ok) {
        throw userResponse.error || new Error('Error getting user session info.');
      }
      const user = userResponse.ok;
      dispatch({
        type: Types.LOGIN,
        payload: { user },
      });
    },
    []
  );

  const confirmResetPasswordToken: JWTContextType['confirmResetPasswordToken'] = useCallback(
    async (token: string): Promise<void> => {
      const config = {
        params: {
          token,
        },
      };
      const response = await axios.post('/v1/user/confirm-reset-token', {}, config);

      const storageAvailable = localStorageAvailable();

      const { access_token } = response.data;

      storageAvailable && localStorage.setItem('accessTokenResetPassword', access_token);
    },
    []
  );

  // GET USER
  const getUser: JWTContextType['getUser'] = useCallback(async () => {
    try {
      const userResponse = await api.getAxiosUserSession();
      if (!userResponse.ok) {
        throw userResponse.error || new Error('Error getting user session info.');
      }
      const user = userResponse.ok;
      // here we are missing domainID
      dispatch({
        type: Types.GET_USER,
        payload: { user },
      });
      return { ok: user };
    } catch (error) {
      console.log(error);
      return { error };
    }
  }, []);

  // ----------------------------------------------------------------------

  const onSocialLoginError = (error: Error) => {
    console.error(error);
    dispatch({
      type: Types.SOCIAL_AUTH,
      payload: { error },
    });
  };

  const onSocialLoginUnauthorized = async (status: number, token: SocialUserInfo) => {
    if (![401, 403].includes(status)) {
      return;
    }
    const userInfo = await token.moreInfo(token);
    if (userInfo?.error || !(userInfo?.ok?.email && userInfo.ok.name)) {
      onSocialLoginError(
        new Error(
          userInfo?.error?.message ??
            `Failed to get email address from ${token?.provider ?? 'provider'}.`
        )
      );
      return;
    }
    dispatch({
      type: Types.SOCIAL_AUTH,
      payload: { ok: userInfo.ok },
    });

    switch (status) {
      case 401:
        // precisa confirmar com senha
        return dispatch({
          type: Types.SET_SOCIAL_AUTH_CONFIRMATION_NEEDED,
          payload: true,
        });
      case 403:
        // precisa completar cadastro
        return dispatch({
          type: Types.SET_PROFILE_COMPLETION_NEEDED,
          payload: true,
        });
    }
  };

  const onSocialLogin = async (token: SocialUserInfo): Promise<void> => {
    dispatch({
      type: Types.SOCIAL_AUTH,
      payload: { ok: token },
    });
    if (axios.defaults.headers.common.Authorization) {
      const userInfo = await token.moreInfo(token);
      if (userInfo?.error || !(userInfo?.ok?.email && userInfo.ok.name)) {
        onSocialLoginError(
          new Error(
            userInfo?.error?.message ??
              `Failed to get email address from ${token?.provider ?? 'provider'}.`
          )
        );
        return;
      }
      dispatch({
        type: Types.SOCIAL_AUTH,
        payload: { ok: userInfo.ok },
      });
      dispatch({
        type: Types.SET_SOCIAL_AUTH_ADD_USER_EDIT,
        payload: true,
      });

      return;
    }

    const auth = await resultAsync<AxiosResponse<LoginSuccess, LoginInput>, LoginError>(
      axios.post('/v1/auth/login', { token: token.token, token_provider: token.provider })
    );
    if (!auth?.ok?.data?.access_token) {
      if (!!auth?.error?.status && [401, 403].includes(auth.error.status)) {
        onSocialLoginUnauthorized(auth.error.status, token);
      } else {
        onSocialLoginError(
          new Error(
            auth?.error?.message ??
              auth?.error?.error ??
              `Failed to login with ${token?.provider ?? 'provider'}.`
          )
        );
      }
      return;
    }
    const { access_token, refresh_token } = auth.ok.data;
    setSession(access_token, refresh_token);

    const userResponse = await api.getAxiosUserSession();
    if (!userResponse.ok) {
      throw userResponse.error || new Error('Error getting user session info.');
    }
    const user = userResponse.ok;

    dispatch({
      type: Types.LOGIN,
      payload: { user },
    });
  };

  const socialAuthConfirm: JWTContextType['socialAuthConfirm'] = useCallback(
    async (userConfirm: ConfirmSocialLoginInput) => {
      const response = await resultAsync<
        AxiosResponse<ConfirmSocialLoginSuccess, ConfirmSocialLoginInput>,
        LoginError
      >(axios.post('/v1/auth/confirm-social-login', userConfirm));
      if (!response?.ok?.data?.access_token) {
        return {
          error: response?.error || {
            status: 0,
            message: `Failed to POST /v1/auth/confirm-social-login`,
          },
        };
      }
      dispatch({
        type: Types.SET_SOCIAL_AUTH_CONFIRMATION_NEEDED,
        payload: false,
      });

      setSession(response.ok.data.access_token, response.ok.data.refresh_token);
      const userResponse = await api.getAxiosUserSession();
      if (!userResponse.ok) {
        throw userResponse.error || new Error('Error getting user session info.');
      }
      const user = userResponse.ok;
      dispatch({
        type: Types.LOGIN,
        payload: { user },
      });
      return {
        ok: {
          ...response?.ok?.data,
        },
      };
    },
    []
  );

  const updateInvite: JWTContextType['updateInvite'] = useCallback(async (body) => {
    if (!axios.defaults.headers.common.Authorization) {
      return { error: new Error('No Authorization header') };
    }

    const response = await resultAsync<
      AxiosResponse<GenericSuccessResponse, UpdateInviteInput>,
      GenericErrorResponse
    >(axios.post('/v1/user/invite', body));

    if (response.ok?.data?.status !== 200) {
      return {
        error: new Error(
          response.error?.message || 'Failed to POST  /v1/user/invite   UpdateInvite'
        ),
      };
    }
    return { ok: true };
  }, []);

  const memoizedValue = useMemo<JWTContextType>(
    () => ({
      ...state,
      method: 'jwt',
      usernamePasswordlogin,
      refreshToken,
      register,
      logout,
      confirmEmail,
      confirmEmailSocial,
      resendConfirmEmail,
      sendResetPasswordEmail,
      confirmResetPassword,
      confirmResetPasswordToken,
      getUser,
      socialAuthConfirm,
      updateInvite,
    }),
    [
      state,
      usernamePasswordlogin,
      refreshToken,
      logout,
      register,
      confirmEmail,
      confirmEmailSocial,
      resendConfirmEmail,
      sendResetPasswordEmail,
      confirmResetPassword,
      confirmResetPasswordToken,
      getUser,
      socialAuthConfirm,
      updateInvite,
    ]
  );

  return (
    <JwtAuthContext.Provider value={memoizedValue}>
      <GoogleAuthContextProvider onLoginWithGoogle={onSocialLogin}>
        <MicrosoftAuthContextProvider onLogin={onSocialLogin}>
          {children}
        </MicrosoftAuthContextProvider>
      </GoogleAuthContextProvider>
    </JwtAuthContext.Provider>
  );
}
