import axios, { clearSession } from '../utils/axios';

export function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      // A -> 65 -> 0x41 -> %41
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

/**
 * To be used to mock a JWT token
 */
export function mock_jwtEncode(payload: { expiresAt: Date; user_id: number }): string {
  const { expiresAt, user_id } = payload;
  const exp = expiresAt.getTime() / 1000;

  const json = JSON.stringify({ exp, user_id });
  // no need to encode via decodeURIComponent and stringToUriEncondedHex
  // as '{"exp":1716605994000,"user_id":2}' does not contain special characters
  const base64 = Buffer.from(json).toString('base64');
  const base64Url = base64.replace(/\+/g, '-').replace(/\//g, '_');
  return `mock.${base64Url}.mock`;
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken?: string | null) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (!refreshToken) {
    return;
  }
  const response = await axios.post('/v1/auth/refresh', {
    refresh_token: refreshToken,
  });

  const { access_token, refresh_token } = response.data;

  setSession(access_token, refresh_token);
};

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    try {
      await refreshToken();
    } catch {
      clearSession();
    }
  }, timeLeft);
};

export const accessTokenExpired = (exp: number, refreshTokenFn: () => Promise<void>) => {
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  let accessTokenExpiredTimer;

  clearTimeout(accessTokenExpiredTimer);

  accessTokenExpiredTimer = setTimeout(async () => {
    try {
      await refreshToken();
    } catch {
      clearSession();
    }
  }, timeLeft);
};

export const setSession = (accessToken?: string | null, refreshToken?: string | null) => {
  if (accessToken && refreshToken) {
    try {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    } catch (error) {}

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    clearSession();
  }
};
