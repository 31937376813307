import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBreadcrumb {
  name: string;
  href?: string;
  onClick?: () => void;
}

interface IBreadcrumbState {
  breadcrumbs: IBreadcrumb[];
}

const initialState: IBreadcrumbState = {
  breadcrumbs: [
    { name: 'Home', href: '/' },
    { name: 'Dashboard', href: '/dashboard' },
  ],
};

const breadcrumbSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbs(state, action: PayloadAction<IBreadcrumb[]>) {
      state.breadcrumbs = action.payload;
    },
  },
});

export const { setBreadcrumbs } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;