import { Theme } from '@mui/material/styles';
import { CustomPalette } from '@mui/material/styles/createPalette';

// ----------------------------------------------------------------------

export default function Card(theme: Theme) {
  const pallete: CustomPalette = theme.palette as CustomPalette;
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: theme.customShadows.card,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          ...(pallete.mode === 'login' && pallete.border?.card
            ? {
                borderColor: pallete.border.card,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: Number(theme.shape.borderRadius) * 5,
              }
            : {}),
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  };
}
