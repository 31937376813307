import { TypographyOptions } from '@mui/material/styles/createTypography';

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

// const FONT_PRIMARY = 'Catamaran'; // Google Font
// const FONT_SECONDARY = 'Share';
// const FONT_TERTIARY = 'Hind Vadodara';
// const FONT_PUBLIC_SANS = 'Public Sans';
// // const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

export const typography = (
  FONT_PRIMARY = 'Catamaran', // Google Font
  FONT_SECONDARY = 'Share',
  FONT_TERTIARY = 'Hind Vadodara',
  FONT_PUBLIC_SANS = 'Public Sans'
) =>
  ({
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      lineHeight: 80 / 64,
      fontSize: pxToRem(40),
      ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
    },
    h2: {
      fontWeight: 800,
      lineHeight: 64 / 48,
      fontSize: pxToRem(32),
      ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(32),
      ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(20),
      ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(18),
      ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(17),
      ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    detailsTitle: {
      fontWeight: 600,
      fontFamily: FONT_TERTIARY,
      fontSize: pxToRem(16),
      lineHeight: 16 / 12,
    },
    detailsNumber: {
      fontWeight: 700,
      fontFamily: FONT_PUBLIC_SANS,
      fontSize: pxToRem(48),
      lineHeight: '48px',
    },
    subtitle1: {
      fontFamily: FONT_TERTIARY,
      fontWeight: 500,
      lineHeight: 1.5,
      fontSize: pxToRem(14),
    },
    subtitle2: {
      fontFamily: FONT_PUBLIC_SANS,
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
    },
    subtitle3: {
      fontFamily: FONT_SECONDARY,
      fontWeight: 500,
      lineHeight: 22 / 14,
      fontSize: pxToRem(16),
      color: 'Grey.600',
    },
    body1: {
      fontFamily: FONT_TERTIARY,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    body2: {
      fontFamily: FONT_TERTIARY,
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
    },
    body3: {
      fontFamily: FONT_TERTIARY,
      lineHeight: 22 / 14,
      fontWeight: 400,
      fontSize: pxToRem(14),
    },
    deviceContent: {
      fontWeight: 600,
      fontFamily: FONT_TERTIARY,
      fontsSize: pxToRem(16),
      lineHeight: 24 / 16,
    },
    caption: {
      lineHeight: 18 / 12,
      fontSize: pxToRem(12),
      fontWeight: 400,
      fontFamily: FONT_TERTIARY,
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      textTransform: 'uppercase',
    },
    button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14),
      textTransform: 'capitalize',
    },
    cardDescription: {
      fontWeight: 700,
      fontSize: pxToRem(16),
      lineHeight: 1.5,
    },
  } as TypographyOptions);
