import { createContext, useReducer, useCallback, useMemo } from 'react';

import axios from '../../utils/axios';
import localStorageAvailable from '../../utils/localStorageAvailable';

import { ActionMapType, LogsStateType, LogsType, LogsContextType } from './LogsContextType';
import { ContextType } from '../../context/ContextTypes';

enum Types {
  INITIAL = 'INITIAL',
  GETLOGS = 'GETLOGS',
  GETLOGSFILTERS = 'GETLOGSFILTERS',
  GETTIMESTAMPFILTERS = 'GETTIMESTAMPFILTERS',
  GETLOGSVOLUMETRY = 'GETLOGSVOLUMETRY',
}

type PayLoad = {
  [Types.INITIAL]: {
    isInitialized: boolean;
    logs: LogsType;
    totalLogs: number;
  };
  [Types.GETLOGS]: {
    logs: LogsType;
    totalLogs: number;
  };
  [Types.GETLOGSFILTERS]: {
    logsFilter: LogsType;
    totalLogsFilter: number;
  };
  [Types.GETTIMESTAMPFILTERS]: {
    timestampFilter: LogsType;
    totaltimestampFilter: number;
  };
  [Types.GETLOGSVOLUMETRY]: {
    logsVolumetry: LogsType;
    totalLogsVolumetry: number;
  };
};

type ActionsType = ActionMapType<PayLoad>[keyof ActionMapType<PayLoad>];

const initialState: LogsStateType = {
  isInitialized: false,
  logs: [], // Initialize as an empty object
  totalLogs: 0,
  logsFilter: [],
  totalLogsFilter: 0,
  timestampFilter: [],
  totaltimestampFilter: 0,
  logsVolumetry: [],
  totalLogsVolumetry: 0,
};

const reducer = (state: LogsStateType, action: ActionsType): LogsStateType => {
  switch (action.type) {
    case Types.INITIAL:
      const { isInitialized, logs, totalLogs } = action.payload;
      return {
        ...state,
        isInitialized,
        logs,
        totalLogs,
      };
    case Types.GETLOGS:
      const { logs: newLogs, totalLogs: newTotalLogs } = action.payload;
      return {
        ...state,
        logs: newLogs,
        totalLogs: newTotalLogs,
      };
    case Types.GETLOGSFILTERS:
      const { logsFilter: newFilter, totalLogsFilter: newFilterLogs } = action.payload;
      return {
        ...state,
        logsFilter: newFilter,
        totalLogsFilter: newFilterLogs,
      };
    case Types.GETTIMESTAMPFILTERS:
      const { timestampFilter: newTimestampFilter, totaltimestampFilter: newTimestempFilterLogs } =
        action.payload;
      return {
        ...state,
        timestampFilter: newTimestampFilter,
        totaltimestampFilter: newTimestempFilterLogs,
      };
    case Types.GETLOGSVOLUMETRY:
      const { logsVolumetry: newLogsVolumetry, totalLogsVolumetry: newTotalLogsVolumetry } =
        action.payload;
      return {
        ...state,
        logsVolumetry: newLogsVolumetry,
        totalLogsVolumetry: newTotalLogsVolumetry,
      };
    default:
      return state;
  }
};

export const LogsContext = createContext<LogsContextType | null>(null);

type ContextProviderProps = {
  children: React.ReactNode;
};

export function LogProvider({ children }: ContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const getLogs = useCallback(
    async (contextId: string[], timestamp: string) => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : null;

        if (!accessToken) {
          throw new Error('No access token found');
        }

        const response = await axios.get('/v1/data-engine/events', {
          params: {
            contexts: contextId.join(','),
            timestamp: timestamp,
          },
        });
        dispatch({
          type: Types?.GETLOGS,
          payload: {
            logs: response?.data,
            totalLogs: response?.data.length,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [storageAvailable]
  );

  const getLogsFilters = useCallback(
    async (contexts: string[], timestamp: string, filterSearch: string[][]) => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : null;

        if (!accessToken) {
          throw new Error('No access token found');
        }
        const url = `/v1/data-engine/events?contexts=${contexts.join(
          ','
        )}&timestamp=${timestamp}&${filterSearch}`;

        const response = await axios.get(url);
        if (response.data !== null) {
          dispatch({
            type: Types?.GETLOGSFILTERS,
            payload: {
              logsFilter: response?.data,
              totalLogsFilter: response?.data.length,
            },
          });
        } else {
          console.error('API returned null data');
          return [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    [storageAvailable]
  );

  const getTimestampFilters = useCallback(
    async (contextId: string[], timestamp: string) => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : null;

        if (!accessToken) {
          throw new Error('No access token found');
        }

        const response = await axios.get('/v1/data-engine/events', {
          params: {
            contexts: contextId.join(','),
            timestamp: timestamp,
          },
        });
        dispatch({
          type: Types.GETTIMESTAMPFILTERS,
          payload: {
            timestampFilter: response.data,
            totaltimestampFilter: response.data.length,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [storageAvailable]
  );

  const getLogsVolumetry = useCallback(
    async (contextId: string[]) => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : null;

        if (!accessToken) {
          throw new Error('No access token found');
        }

        const response = await axios.get('/v1/data-engine/events/by-day', {
          params: {
            contexts: contextId.join(','),
          },
        });
        dispatch({
          type: Types?.GETLOGSVOLUMETRY,
          payload: {
            logsVolumetry: response?.data,
            totalLogsVolumetry: response?.data.length,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [storageAvailable]
  );

  const memorizedValue = useMemo(
    () => ({
      logs: state.logs,
      totalLogs: state.totalLogs,
      getLogs,
      getLogsFilters,
      getTimestampFilters,
      logsFilter: state.logsFilter,
      totalLogsFilter: state.totalLogsFilter,
      timestampFilter: state.timestampFilter,
      totaltimestampFilter: state.totaltimestampFilter,
      getLogsVolumetry,
      logsVolumetry: state.logsVolumetry,
      totalLogsVolumetry: state.totalLogsVolumetry,
    }),
    [
      state.logs,
      state.totalLogs,
      state.logsFilter,
      state.totalLogsFilter,
      state.timestampFilter,
      state.totaltimestampFilter,
      state.logsVolumetry,
      state.totalLogsVolumetry,
      getLogs,
      getLogsFilters,
      getTimestampFilters,
      getLogsVolumetry,
    ]
  );

  return <LogsContext.Provider value={memorizedValue}>{children}</LogsContext.Provider>;
}
