import { createContext, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { isLoggedIn } from '../../utils/axios';
import {
  ActionMapType,
  DashboardStateType,
  DashboardContextType,
  OffensesByPeriod,
  OffensesByContext,
  OffensesKPIs,
  MeanTimeKPIS,
  StatusOffensesKPIs,
  OffensesByIntegration,
  TopMetrics,
} from './DashboardContextTypes';

enum Types {
  INITIAL = 'INITIAL',
  GET_OFFENCES_KPIS = 'GET_OFFENCES_KPIS',
  GET_OFFENCES_METRICS = 'GET_OFFENCES_METRICS',
  GET_MEAN_TIME_KPIS = 'GET_MEAN_TIME_KPIS',
  GET_TOP_METRICS = 'GET_TOP_METRICS',
  GET_INTEGRATION_METRICS = 'GET_INTEGRATION_METRICS',
  GET_STATUS_OFFENSES_KPIS = 'GET_STATUS_OFFENSES_KPIS',
}

type PayLoad = {
  [Types.INITIAL]: {
    isInitialized: boolean;
  };
  [Types.GET_OFFENCES_KPIS]: Partial<{
    offensesKPISDay: OffensesKPIs;
    offensesKPISMonth: OffensesKPIs;
    offensesKPISYear: OffensesKPIs;
  }>;
  [Types.GET_OFFENCES_METRICS]: Partial<{
    offensesByContextDay: OffensesByContext;
    offensesByContextMonth: OffensesByContext;
    offensesByContextYear: OffensesByContext;
    offensesByDay: OffensesByPeriod;
    offensesByMonth: OffensesByPeriod;
    offensesByYear: OffensesByPeriod;
  }>;
  [Types.GET_MEAN_TIME_KPIS]: {
    meanTimeKPIS: MeanTimeKPIS;
  };
  [Types.GET_TOP_METRICS]: Partial<{
    top5MetricsDay: TopMetrics;
    top5MetricsMonth: TopMetrics;
    top5MetricsYear: TopMetrics;
  }>;
  [Types.GET_INTEGRATION_METRICS]: Partial<{
    integrationMetricsDay: OffensesByIntegration;
    integrationMetricsMonth: OffensesByIntegration;
    integrationMetricsYear: OffensesByIntegration;
  }>;
  [Types.GET_STATUS_OFFENSES_KPIS]: {
    statusOffensesKPIs: StatusOffensesKPIs;
  };
};

type ActionsType = ActionMapType<PayLoad>[keyof ActionMapType<PayLoad>];

const initialState: DashboardStateType = {
  isInitialized: false,
  offensesKPISDay: null,
  offensesKPISMonth: null,
  offensesKPISYear: null,
  offensesByContextDay: null,
  offensesByContextMonth: null,
  offensesByContextYear: null,
  offensesByDay: null,
  offensesByMonth: null,
  offensesByYear: null,
  meanTimeKPIS: null,
  top5MetricsDay: null,
  top5MetricsMonth: null,
  top5MetricsYear: null,
  integrationMetricsDay: null,
  integrationMetricsMonth: null,
  integrationMetricsYear: null,
  statusOffensesKPIs: null,
};

const reducer = (state: DashboardStateType, action: ActionsType): DashboardStateType => {
  switch (action.type) {
    case Types.INITIAL: {
      return {
        ...state,
        isInitialized: true,
      };
    }
    case Types.GET_OFFENCES_KPIS: {
      return {
        ...state,
        offensesKPISDay: action.payload.offensesKPISDay ?? null,
        offensesKPISMonth: action.payload.offensesKPISMonth ?? null,
        offensesKPISYear: action.payload.offensesKPISYear ?? null,
      };
    }
    case Types.GET_OFFENCES_METRICS: {
      return {
        ...state,
        offensesByContextDay: action.payload.offensesByContextDay ?? null,
        offensesByContextMonth: action.payload.offensesByContextMonth ?? null,
        offensesByContextYear: action.payload.offensesByContextYear ?? null,
        offensesByDay: action.payload.offensesByDay ?? null,
        offensesByMonth: action.payload.offensesByMonth ?? null,
        offensesByYear: action.payload.offensesByYear ?? null,
      };
    }
    case Types.GET_MEAN_TIME_KPIS: {
      return {
        ...state,
        meanTimeKPIS: action.payload.meanTimeKPIS,
      };
    }
    case Types.GET_TOP_METRICS: {
      return {
        ...state,
        top5MetricsDay: action.payload.top5MetricsDay ?? null,
        top5MetricsMonth: action.payload.top5MetricsMonth ?? null,
        top5MetricsYear: action.payload.top5MetricsYear ?? null,
      };
    }
    case Types.GET_INTEGRATION_METRICS: {
      return {
        ...state,
        integrationMetricsDay: action.payload.integrationMetricsDay ?? null,
        integrationMetricsMonth: action.payload.integrationMetricsMonth ?? null,
        integrationMetricsYear: action.payload.integrationMetricsYear ?? null,
      };
    }
    case Types.GET_STATUS_OFFENSES_KPIS: {
      return {
        ...state,
        statusOffensesKPIs: action.payload.statusOffensesKPIs,
      };
    }
    default: {
      return state;
    }
  }
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

type DashboardProviderProps = {
  children: React.ReactNode;
};

export function DashboardProvider({ children }: Readonly<DashboardProviderProps>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getOffensesMetrics = useCallback(async (contexts: string[]) => {
    try {
      if (!isLoggedIn()) {
        return;
      }

      /*  const responseDay = await axios.get('/v1/playbooks/offenses-charts', {
          params: {
            contexts: contexts.join(','),
            period: 'day',
          },
        }); */

      const responseMonth = await axios.get('/v1/playbooks/offenses-charts', {
        params: {
          contexts: contexts.join(','),
          period: 'month',
        },
      });

      /* const responseYear = await axios.get('/v1/playbooks/offenses-charts', {
          params: {
            contexts: contexts.join(','),
            period: 'year',
          },
        });*/

      dispatch({
        type: Types.GET_OFFENCES_METRICS,
        payload: {
          //    offensesByContextDay: responseDay.data.offenses_by_context,
          offensesByContextMonth: responseMonth.data.offenses_by_context,
          //  offensesByContextYear: responseYear.data.offenses_by_context,
          //offensesByDay: responseDay.data.offenses_by_period,
          offensesByMonth: responseMonth.data.offenses_by_period,
          //offensesByYear: responseYear.data.offenses_by_period,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getOffencesKPIS = useCallback(async (contexts: string[]) => {
    try {
      if (!isLoggedIn()) {
        return;
      }

      // Falta adicionar os outros periodos
      /*  const responseDay = await axios.get('/v1/playbooks/offenses-kpis', {
          params: {
            contexts: contexts.join(','),
            period: 'day',
          },
        });
*/
      const responseMonth = await axios.get('/v1/playbooks/offenses-kpis', {
        params: {
          contexts: contexts.join(','),
          period: 'month',
        },
      });
      /*
        const responseYear = await axios.get('/v1/playbooks/offenses-kpis', {
          params: {
            contexts: contexts.join(','),
            period: 'year',
          },
        }); */

      dispatch({
        type: Types.GET_OFFENCES_KPIS,
        payload: {
          //   offensesKPISDay: responseDay.data.offenses_kpis,
          offensesKPISMonth: responseMonth.data.offenses_kpis,
          //   offensesKPISYear: responseYear.data.offenses_kpis,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getIntegrationMetrics = useCallback(async (contexts: string[]) => {
    try {
      if (!isLoggedIn()) {
        return;
      }

      /*    const responseDay = await axios.get('/v1/playbooks/integrations-metrics', {
          params: {
            contexts: contexts.join(','),
            period: 'day',
          },
        });
*/
      const responseMonth = await axios.get('/v1/playbooks/integrations-metrics', {
        params: {
          contexts: contexts.join(','),
          period: 'month',
        },
      });
      /*
        const responseYear = await axios.get('/v1/playbooks/integrations-metrics', {
          params: {
            contexts: contexts.join(','),
            period: 'year',
          },
        });
*/
      dispatch({
        type: Types.GET_INTEGRATION_METRICS,
        payload: {
          // integrationMetricsDay: responseDay.data.offenses_by_integration,
          integrationMetricsMonth: responseMonth.data.offenses_by_integration,
          // integrationMetricsYear: responseYear.data.offenses_by_integration,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getMeanTimeKPIS = useCallback(async (contexts: string[]) => {
    try {
      if (!isLoggedIn()) {
        return;
      }

      // Falta Adicionar os outros periodos
      const response = await axios.get('/v1/playbooks/mean-time-kpis', {
        params: {
          contexts: contexts.join(','),
        },
      });

      dispatch({
        type: Types.GET_MEAN_TIME_KPIS,
        payload: {
          meanTimeKPIS: response.data.mean_time_kpis,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getTop5Metrics = useCallback(async (contexts: string[]) => {
    try {
      if (!isLoggedIn()) {
        return;
      }
      /*
        const responseDay = await axios.get('/v1/playbooks/top-metrics', {
          params: {
            contexts: contexts.join(','),
            period: 'day',
          },
        });
        */
      const responseMonth = await axios.get('/v1/playbooks/top-metrics', {
        params: {
          contexts: contexts.join(','),
          period: 'month',
        },
      });

      /*
        const responseYear = await axios.get('/v1/playbooks/top-metrics', {
          params: {
            contexts: contexts.join(','),
            period: 'year',
          },
        });
*/
      dispatch({
        type: Types.GET_TOP_METRICS,
        payload: {
          //top5MetricsDay: responseDay.data,
          top5MetricsMonth: responseMonth.data,
          // top5MetricsYear: responseYear.data,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getOffensesStatusKPIS = useCallback(async (context: string[]) => {
    try {
      if (!isLoggedIn()) {
        return;
      }
      // Periodo ta espeficado aqui, pq no futuro talvez entre a opções para outros periodos
      const response = await axios.get('/v1/playbooks/offenses-status-kpis', {
        params: {
          contexts: context.join(','),
          period: 'month',
        },
      });
      dispatch({
        type: Types.GET_STATUS_OFFENSES_KPIS,
        payload: {
          statusOffensesKPIs: response.data.status_offenses_kpis,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const memorizedValue = useMemo<DashboardContextType>(
    () => ({
      ...state,
      getOffensesMetrics,
      getOffencesKPIS,
      getMeanTimeKPIS,
      getIntegrationMetrics,
      getTop5Metrics,
      getOffensesStatusKPIS,
    }),
    [
      getOffensesMetrics,
      getOffencesKPIS,
      getMeanTimeKPIS,
      getIntegrationMetrics,
      getTop5Metrics,
      getOffensesStatusKPIS,
      state,
    ]
  );

  return <DashboardContext.Provider value={memorizedValue}>{children}</DashboardContext.Provider>;
}
