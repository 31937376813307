import { Suspense, lazy, ElementType } from 'react';
import LoadingScreen from '../components/loading-screen/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(
  lazy(() => import('../pages/auth/LoginPage').then((module) => ({ default: module.LoginPage })))
);
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const CompleteSingupPage = Loadable(lazy(() => import('../pages/auth/CompleteSingupPage')));
export const ConfirmSocialAuthPage = Loadable(
  lazy(() => import('../pages/auth/ConfirmSocialAuthPage'))
);
export const FeatureExplorer = Loadable(
  lazy(() =>
    import('../pages/auth/FeatureExplorer').then((module) => ({ default: module.FeatureExplorer }))
  )
);

export const HomePageLazy = Loadable(lazy(() => import('../pages/home/HomePage')));
// DASHBOARD
export const DashboardPageLazy = Loadable(lazy(() => import('../pages/dashboard/DashboardPage')));
export const DashboardPageDynamic = Loadable(
  lazy(() => import('../pages/dashboard/DashboardDynamicPage'))
);

// DASHBOARD: CONTEXT MANAGEMENT
export const ContextManagementPage = Loadable(
  lazy(() => import('../pages/contextManagement/ContextManagementPage'))
);

// DASHBOARD: DOMAIN MANAGEMENT
export const DomainManagementPage = Loadable(
  lazy(() => import('../pages/contextManagement/DomainManagementPage'))
);

// DASHBOARD: ORGANIZATION MANAGEMENT
export const OrganizationManagementPage = Loadable(
  lazy(() => import('../pages/contextManagement/OrganizationManagementPage'))
);

// DASHBOARD: LOGS
export const LogsPage = Loadable(lazy(() => import('../pages/logs/LogsPage')));

// DASHBOARD: ENDPOINTS
export const EndpointsPage = Loadable(lazy(() => import('../pages/endpoints/EndpointsPage')));
export const EndpointsDetailsPage = Loadable(
  lazy(() => import('../pages/endpoints/EndpointsDetailsPage'))
);

// DASHBOARD: Integrations
export const IntegrationsPage = Loadable(
  lazy(() => import('../pages/integrations/IntegrationsPage'))
);

export const AlertDetailsPage = Loadable(
  lazy(() =>
    import('../pages/alerts/AlertDetailsPage').then((module) => ({
      default: module.AlertDetailsPage,
    }))
  )
);
export const AlertsListpage = Loadable(
  lazy(() =>
    import('../pages/alerts/AlertsListPage').then((module) => ({
      default: module.AlertsListpage,
    }))
  )
);

// DASHBOARD: VULNERABILITIES
export const VulnerabilitiesPage = Loadable(
  lazy(() => import('../pages/vulnerabilities/VulnerabilitiesPage'))
);

// DASHBOARD: PLAYBOOK
export const PlaybookPage = Loadable(lazy(() => import('../pages/playbook/PlaybookPage')));
export const PlaybookBuildPage = Loadable(
  lazy(() => import('../pages/playbook/PlaybookBuildPage'))
);

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const FirstAccessPage = Loadable(lazy(() => import('../pages/FirstAccessPage')));

// USER
export const UserProfilePage = Loadable(
  lazy(() =>
    import('../pages/user/UserProfilePage').then((module) => ({ default: module.UserProfilePage }))
  )
);
