import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { FEATURES, HOST_API_KEY, VERSION } from '../config-global';
import { PATH_AUTH } from '../routes/paths';
import { result } from './result';

const axiosInstance = axios.create({
  baseURL: HOST_API_KEY,
});

/**
 * Generate correlation ID.
 *
 * @example Web_126.ABCD1234
 */
export function correlationId() {
  return `Web_${VERSION}.${Math.random().toString(36).substring(2, 9)}`;
}

if (FEATURES.CORRELATION_ID) {
  axiosInstance.interceptors.request.use((config) => {
    // Generate correlation ID
    config.headers['X-Correlation-ID'] = correlationId();
    return config;
  });
}

export function clearSession() {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } catch (error) {}

  delete axiosInstance.defaults.headers.common.Authorization;
  if (process.env.NODE_ENV !== 'test') {
    window.location.href = PATH_AUTH.login;
  }
}

export function clearSessionSocial() {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } catch (error) {}

  delete axiosInstance.defaults.headers.common.Authorization;
  if (process.env.NODE_ENV !== 'test') {
    window.location.href = PATH_AUTH.resetPassword;
  }
}

export function isLoggedIn() {
  return !!axiosInstance.defaults.headers.common.Authorization;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<string, AxiosRequestConfig>) => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        `Axios reject on ${error?.config?.method?.toUpperCase()} ${error?.config?.url}`,
        error?.message,
        error,
        process.env.NODE_ENV
      );
    }
    const isTokenError = result(() => {
      return (
        [
          // should be only 401, but api is kinda waky
          400, 401,
        ].includes(error.response?.status ?? 0) &&
        JSON.parse(error?.response?.data ?? '{}')?.message === 'invalid token'
      );
    });
    if (isTokenError.ok === true) {
      clearSession();
    }
    return Promise.reject(error?.response?.data ?? error?.message ?? 'Something went wrong');
  }
);

export default axiosInstance;
