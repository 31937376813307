import { Color } from '@mui/material';
import { PaletteColor, PaletteOptions, alpha } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export type { ColorSchema } from './types';
// ----------------------------------------------------------------------

// SETUP COLORS

const GREY: Color = {
  ...grey,
  50: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#CFD4E1',
  light: '#F4F6F8',
  main: '#404D6C',
  dark: '#333E56',
  darker: '#262E41',
  contrastText: '#FFFFFF',
} as const;

const SECONDARY = {
  lighter: '#B3CDE3',
  light: '#80ABD0',
  dark: '#0057A0',
  darker: '#004680',
  main: '#003460',
  contrastText: '#FFF',
} as const;

const TERTIARY: PaletteColor = {
  lighter: '#DDE1EA',
  light: '#C6CDDC',
  main: '#8D9AB8',
  dark: '#717B93',
  darker: '#555C6E',
  contrastText: '#000',
};

const PELOROUS: PaletteColor = {
  lighter: '#C8ECEC',
  light: '#A4E0E0',
  main: '#48C0C1',
  dark: '#3A9A9A',
  darker: '#2B7374',
  contrastText: '#000',
};

const PURPLE: PaletteColor = {
  lighter: '#C7C0DA',
  light: '#A296C2',
  main: '#442C84',
  dark: '#36236A',
  darker: '#291A4F',
  contrastText: '#FFF',
};

const YELLOW_GREEN: PaletteColor = {
  lighter: '#ECF3C0',
  light: '#E0EB97',
  main: '#C1D72E',
  dark: '#9AAC25',
  darker: '#74811C',
  contrastText: '#000',
};

const LIME: PaletteColor = {
  lighter: '#DCF0BF',
  light: '#C4E794',
  main: '#89CE28',
  dark: '#6EA520',
  darker: '#527C18',
  contrastText: '#000',
};

const INFO: PaletteColor = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS: PaletteColor = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#FFFFFF',
};

const WARNING: PaletteColor = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR: PaletteColor = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const COMMON: PaletteOptions = {
  contrastThreshold: 4.5,
  common: { black: '#000000', white: '#FFFFFF' },
  primary: PRIMARY,
  secondary: SECONDARY,
  tertiary: TERTIARY,
  lime: LIME,
  yellowGreen: YELLOW_GREEN,
  purple: PURPLE,
  pelorous: PELOROUS,
  info: INFO,
  success: SUCCESS,
  healthy: alpha(SUCCESS.main, 0.16),
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  chartHorizontalBarsError: alpha(ERROR.main, 0.68),
  tableHeaderBackground: alpha(SECONDARY.lighter, 0.15),
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    subHover: alpha(SECONDARY.darker, 0.18),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  table: {
    odd: 'white',
    even: 'rgba(0, 87, 160, 0.05)',
  },
  // No futuro jogar isso aqui para palheta quando tiver todas as variações nessas cores.
  criticality: {
    All: {
      text: PRIMARY.main,
      background: PRIMARY.lighter,
      dark: '#00000000',
    },
    Critical: {
      background: '#F0D9D9',
      text: '#B71D18',
      dark: '#B71D18',
    },
    High: {
      background: '#FAEBDE',
      text: '#DE8531',
      dark: '#DE8531',
    },
    Medium: {
      background: '#FFF2D6',
      text: '#FFAB00',
      dark: '#FFAB00',
    },
    Low: {
      background: '#CDECF3',
      text: '#006C9C',
      dark: '#006C9C',
    },
    Information: {
      text: '#1FB569',
      background: '#CEE9DC',
      dark: '#CEE9DC',
    },
    Resolved: {
      background: '#CEE9DC',
      text: '#1FB569',
      dark: '#1FB569',
    },
  },
};

export const LIGHT: PaletteOptions = {
  ...COMMON,
  mode: 'light',
  text: {
    primary: PRIMARY.main,
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: { paper: '#FFFFFF', default: '#F0F4FA', neutral: GREY[200] },
  table: {
    odd: 'white',
    even: 'rgba(0, 87, 160, 0.05)',
  },
  action: {
    ...COMMON.action,
    active: GREY[600],
  },
  graphics: [
    PELOROUS.main,
    PURPLE.main,
    YELLOW_GREEN.main,
    LIME.main,
    '#c2e98e',
    '#ff88dc',
    '#efb8c8',
    '#d0bcff',
    '#aabdd2',
    '#aad6d2',
  ],
} as const;

export const DARK: PaletteOptions = {
  ...COMMON,
  mode: 'dark',
  contrastThreshold: 4.5,
  text: {
    primary: '#fff',
    disabled: '#919eab',
  },
  background: {
    default: '#0B1727',
    paper: '#121E2D',
  },
  table: {
    odd: '#1E2D3D',
    even: 'hsla(207, 46%, 80%, 0.05)',
  },
  primary: {
    darker: '#FFFFFF',
    main: '#FFFFFF',
    light: 'rgba(255, 255, 255, .16)',
    lighter: '#FFFFFF',
  },
  secondary: {
    darker: '#00b2ff',
    dark: '#00b2ff',
    main: '#00b2ff',
    lighter: 'rgba(0,178,255,1)',
    // main: '#18dc82',
    // lighter: 'rgba(0, 0, 100, .16)',
  },
  titles: {
    main: '#ffffff',
    sub: '#98a2b3',
    text: '#d0d5dd',
  },
  success: {
    main: '#00de60',
    lighter: 'rgba(0, 222, 96, 0.16)',
  },
  info: {
    main: '#00b8d9',
    lighter: 'rgba(0, 184, 217, 0.16)',
  },
  warning: {
    main: '#ff9838',
    light: '#FFD666',
    lighter: 'rgba(255, 152, 56, 0.16)',
  },
  error: {
    main: '#ff888b',
    lighter: 'rgba(255, 136, 139, 0.16)',
  },
  criticality: {
    ...COMMON.criticality,
    All: {
      text: '#FFFFFF',
      background: PRIMARY.main,
      dark: PRIMARY.main,
    },
  },
  graphics: [
    '#39becd',
    '#AA78FF',
    '#00d9bf',
    '#c1d72e',
    '#ff88dc',
    '#97f554',
    '#efb8c8',
    '#c2e98e',
    '#d0bcff',
    '#aabdd2',
    '#aad6d2',
  ],
};

export const LOGIN: PaletteOptions = {
  ...DARK,
  // mode: 'login',
  background: {
    default: '#0B1727',
    paper: 'rgba(2, 17, 42, 0.66)',
  },
  border: {
    card: 'rgb(15, 40, 64)', // #0F2840
    input: '#47C1C2',
  },
  secondary: {
    ...DARK.secondary,
    main: '#47C1C2',
  },
  error: {
    main: '#ff888b',
    ...DARK.error,
    contrastText: '#ff888b36',
  },
};
