import { IIntegrationAgent } from 'src/@types/integrations';

export const mockDevices = {
  id: '2b1022aa-71c3-4086-ac24-38c8e07edc2c',
  version: 'version',
  registred: false,
  context_id: '18',
  token: '18_2b1022aa-71c3-4086-ac24-38c8e07edc2c',
  name: 'name',
  description: 'description',
  status: 'status',
  statusChanged: 0,
  deviceTunnelIP: 'deviceTunnelIP',
  tunnelName: 'tunnelName',
  data: {
    hostname: 'hostname',
    ip: 'ip',
    os: 'os',
    osVersion: 'osVersion',
    osArch: 'osArch',
    osFamily: 'osFamily',
    osKernel: 'osKernel',
    memoryTotal: 'memoryTotal',
    cpuCores: 'cpuCores',
    cpuVendor: 'cpuVendor',
    cpuModel: 'cpuModel',
    cpuFrequency: 'cpuFrequency',
    lastLocation: 'lastLocation',
  },
  authorized: false,
  lastSeen: 'lastSeen',
  severity: 0,
  riskScore: 0,
  maturityScore: 0,
  score: 0,
  agentGroupId: 'agentGroupId',
  roles: {
    edr: false,
    antiMalware: false,
    dnsFilter: false,
    audit: false,
    systemLog: false,
    performace: false,
    syslog: false,
    integration: false,
  },
  modules: {
    pdr: false,
    xdr: false,
    mdr: false,
    collector: false,
  },
  key: 'key',
  serverKey: 'serverKey',
  integrations: null,
  template: false,
  validTime: 1719285014,
};

// from postman GET /v1/integrations?contexts=3
export const mockIntegration: IIntegrationAgent = {
  id: 'fffca119-e10c-4549-8baf-6ae279f2e986',
  name: 'inova-wlc01',
  vendor: 'Cisco',
  product: '',
  contextId: '3',
  description: 'This is integration root for Wireless Lan Controller Cisco',
  sourceIp: '10.100.50.250',
  icon: 'http://example.com/icon.png',
  createdBy: 'admin',
  lastUpdate: randomTimestamp(),
  useSetup: true,
  useScriptCollect: true,
  setup: {
    script: '',
    variables: null,
  },
  uninstall: {
    script: '',
    variables: null,
  },
  collect: {
    logParser: '206de892-204d-49f4-ab7a-8306c8511a99',
    templates: [
      '052cba50-e4ef-419c-9973-68bc7cbe2dee',
      'ac1f168c-80f2-4c19-bd30-dba6c1f6fde7',
      '3f41e0fc-4bd7-461c-8b79-0a9129de06ac',
      '58407182-be82-4d99-ad93-b0784b32edd1',
    ],
    folders: null,
    sysEvents: null,
    script: null,
  },
  version: 'v1.0',
  integrationRootId: '0b058ed7-89bb-4666-aa95-e18e5b77a27c',
};

function randomTimestamp() {
  return new Date(Math.random() * +new Date()).toISOString();
}

export const mockIntegrations: IIntegrationAgent[] = [
  {
    id: 'fffca119-e10c-4549-8baf-6ae279f2e986',
    name: 'MOCK inova-wlc01',
    vendor: 'Cisco',
    product: '',
    contextId: '3',
    description: 'This is integration root for Wireless Lan Controller Cisco',
    sourceIp: '10.100.50.250',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '206de892-204d-49f4-ab7a-8306c8511a99',
      templates: [
        '052cba50-e4ef-419c-9973-68bc7cbe2dee',
        'ac1f168c-80f2-4c19-bd30-dba6c1f6fde7',
        '3f41e0fc-4bd7-461c-8b79-0a9129de06ac',
        '58407182-be82-4d99-ad93-b0784b32edd1',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '0b058ed7-89bb-4666-aa95-e18e5b77a27c',
  },
  {
    id: 'a6df6fa0-bc18-42eb-9987-b361a347c5fb',
    name: 'MOCK HSP-ACESSO-PILHA-1PV',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.4',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '8f705758-5992-4fc8-96c2-808a53c86f16',
    name: 'MOCK HSP-ACESSO-PILHA-1SS',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.2',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: 'fa0014db-4b68-4a9b-9baa-617130826174',
    name: 'MOCK HPBSRVDS01',
    vendor: 'Trend Micro',
    product: '',
    contextId: '3',
    description: 'This is a sample integration for Deep Security Manager.',
    sourceIp: '172.20.11.125',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '',
      templates: [
        'bf8fc564-f9bb-4d34-9b91-f725f875badb',
        '225d6b39-b306-46b0-80f4-c15085cf930b',
        '9af6c53a-9528-44e1-ab93-4f0489903ab2',
        '5a9efbf1-346a-4542-a3f6-c3c079b2793f',
      ],
      folders: [],
      sysEvents: null,
      script: null,
    },
    version: '20.0.879',
    integrationRootId: 'c2ed8de5-70cd-41ef-99ab-6be544bb2f3d',
  },
  {
    id: 'fd29bf40-1e03-4d2a-95a7-30797449df78',
    name: 'MOCK SPB-VIV-INOVA-FW',
    vendor: 'Fortinet',
    product: '',
    contextId: '3',
    description: 'This is a sample integration for Fortigate.',
    sourceIp: '172.30.5.1',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: 'd173be72-bab5-41fb-80e2-13331b40d10a',
      templates: ['0f3dae9f-5366-41ba-9c45-0e6dd1f5bc26', 'cb30ae6f-a2ba-4c65-9434-f8f3f5894dff'],
      folders: [],
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '3f9d77e1-9114-403f-ac10-dbeba8f2520f',
  },
  {
    id: '400984ba-076f-4bce-9da1-bc4298044df6',
    name: 'MOCK HSP-ACESSO-PILHA-2PV-02',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.15',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '757d7c3a-2c24-4ff3-8e6d-4188a25ad21f',
    name: 'MOCK HSP-ACESSO-PILHA-3PV',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.6',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '61a13f72-a682-4374-a8bf-525c0bbf4e2d',
    name: 'MOCK HSP-ACESSO-PILHA-4PV-01',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.7',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '77120331-5302-43c9-a636-6bca47ec1190',
    name: 'MOCK HSP-ACESSO-PILHA-4PV-02',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.16',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: 'f4f6c0f5-cc4d-4028-9a3d-3cda259593cf',
    name: 'MOCK HSP-INTERNET',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.12',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: 'a7c8c95f-fc38-4760-906f-c22753b8a0d4',
    name: 'MOCK HSP-ACESSO-DTC',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.11',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: 'acd419e1-edd8-4ff0-86ee-1fa11518abf0',
    name: 'MOCK HSP-ACESSO-PILHA-2PV-01',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.5',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: 'c37653de-7b3d-4d50-946e-a3a00af2586e',
    name: 'MOCK HSP-ACESSO-PILHA-2SS',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.1',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '93df52f9-db73-4f92-9363-dd1c937fa3c2',
    name: 'MOCK HSP-ACESSO-PILHA-TER-01',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.3',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: 'a97d66a9-4257-4f02-8f7f-e73d18f39163',
    name: 'MOCK HSP-ACESSO-PILHA-TER-02',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.14',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '8830c8d0-e1b5-4672-bd1d-ffcf71d1f01e',
    name: 'MOCK HSP-ACESSO-PILHA-6PV',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.9',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '74b17e8f-027f-4a6b-8f0a-ce9803faf217',
    name: 'MOCK HSP-CORE',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '172.20.11.254',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: false,
    useScriptCollect: false,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: 'a6a9f7fb-01a1-4c09-a8de-a456aa7a96bb',
    name: 'MOCK CISCO2811',
    vendor: 'Cisco',
    product: '',
    contextId: '3',
    description: 'This is a sample integration for Router Cisco.',
    sourceIp: '10.100.50.100',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '6e54f718-d351-40ee-b416-2134e205f2ea',
      templates: ['4abab687-6db2-4d71-a5bf-8900e6e4bd4a'],
      folders: [],
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '641776c5-91f0-4039-9eef-32c17dda79b7',
  },
  {
    id: 'd4be040d-0cd9-441f-80ce-8c9ab113ff00',
    name: 'MOCK 10.11.50.8',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.8',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '47a7281a-2caa-416b-bf3b-cde51502e16a',
    name: 'MOCK 10.11.50.10',
    vendor: 'Huawei',
    product: '',
    contextId: '3',
    description: 'This is the default Huawei switch integration root.',
    sourceIp: '10.11.50.10',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: '3451c5f3-26fd-4010-bceb-d7ce62b75511',
      templates: [
        '387f8f7e-ce26-4852-ab46-4a79ea6ff957',
        'cf7c44c5-7249-4677-b1ac-e72abf56785e',
        '99a066ca-1275-45c4-baa9-28bae8a9224d',
        '170dec57-91fe-44ae-9cd4-8220a31f4c48',
        'e7083a03-f261-44fa-a733-2a21969e2e0d',
        '61554387-991c-484c-be7f-033548baff01',
      ],
      folders: null,
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '7b6f7cff-dde1-4fb9-bcc3-3b3782dd12d9',
  },
  {
    id: '2303374f-8f4a-49e5-98c3-2c5734d53bb2',
    name: 'MOCK INOVA-PEROLA-FW',
    vendor: 'Fortinet',
    product: '',
    contextId: '3',
    description: 'This is a sample integration for Fortigate.',
    sourceIp: '10.11.50.200',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: 'd173be72-bab5-41fb-80e2-13331b40d10a',
      templates: ['0f3dae9f-5366-41ba-9c45-0e6dd1f5bc26', 'cb30ae6f-a2ba-4c65-9434-f8f3f5894dff'],
      folders: [],
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '3f9d77e1-9114-403f-ac10-dbeba8f2520f',
  },
  {
    id: 'd4bdc09a-c734-4eb8-a7b7-201bb64302d4',
    name: 'MOCK TrendMicro Apex Central',
    vendor: 'TrendMicro',
    product: '',
    contextId: '3',
    description: 'Integração default do TrendMicro Apex Central',
    sourceIp: '172.20.11.116',
    icon: 'http://example.com/icon.png',
    createdBy: 'admin',
    lastUpdate: randomTimestamp(),
    useSetup: true,
    useScriptCollect: true,
    setup: {
      script: '',
      variables: null,
    },
    uninstall: {
      script: '',
      variables: null,
    },
    collect: {
      logParser: 'eb83d116-a471-4029-a4d1-92dbb4ac2055',
      templates: ['a6a5b3f1-93c3-4971-91ce-8d18bf0d4ef8'],
      folders: [],
      sysEvents: null,
      script: null,
    },
    version: 'v1.0',
    integrationRootId: '5a4664dd-4aae-4f7e-9858-3daf983df462',
  },
];
