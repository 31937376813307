import { createContext, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { isLoggedIn } from '../../utils/axios';
//
import {
  ActionMapType,
  IntegrationStateType,
  IntegrationsType,
  IntegrationsContextType,
  RootIntegrationType,
} from './IntegrationsContextTypes';
import { mockIntegrations } from './IntegrationContextMocks';
import { DEV_MODE } from 'src/config-global';

enum Types {
  INITIAL = 'INITIAL',
  ADDENDPOINT = 'ADDENDPOINT',
  GETSERVER = 'GETSERVER',
  GETSERVERS = 'GETSERVERS',
  GETNETWORK = 'GETNETWORK',
  GETNETWORKS = 'GETNETWORKS',
  GETCOLLECTOR = 'GETCOLLECTOR',
  GETINTEGRATIONS = 'GETINTEGRATIONS',
  GETROOTINTEGRATIONS = 'GETROOTINTEGRATIONS',
  GETENDPOINTRESOURCES = 'GETENDPOINTRESOURCES',
  CONNECTNEWINTEGRATION = 'CONNECTNEWINTEGRATION',
  ATTACHINTEGRATION = 'ATTACHINTEGRATION',
}

type PayLoad = {
  [Types.INITIAL]: {
    isInitialized: boolean;
    integrations: IntegrationsType;
  };
  [Types.GETSERVER]: {
    server: IntegrationsType;
  };
  [Types.GETSERVERS]: {
    servers: IntegrationsType;
  };
  [Types.GETNETWORK]: {
    network: IntegrationsType;
  };
  [Types.GETNETWORKS]: {
    networks: IntegrationsType;
  };
  [Types.GETCOLLECTOR]: {
    collector: IntegrationsType;
  };
  [Types.GETINTEGRATIONS]: {
    integrations: IntegrationsType;
  };
  [Types.GETROOTINTEGRATIONS]: {
    rootIntegrations: RootIntegrationType[];
  };
  [Types.CONNECTNEWINTEGRATION]: {
    newIntegration: IntegrationsType;
  };
  [Types.ATTACHINTEGRATION]: {
    newIntegration: IntegrationsType;
  };
};

type ActionsType = ActionMapType<PayLoad>[keyof ActionMapType<PayLoad>];

// ----------------------------------------------------------------------

// integrations é generico entao provavelmente nao vai ser utilizado em favor de especificos (server, collectors, etc.)
const initialState: IntegrationStateType = {
  isInitialized: false,
  rootIntegrations: [],
};

const reducer = (state: IntegrationStateType, action: ActionsType): IntegrationStateType => {
  switch (action.type) {
    case Types.INITIAL: {
      return {
        ...state,
        isInitialized: true,
        integrations: action.payload.integrations,
      };
    }
    case Types.GETNETWORK: {
      return {
        ...state,
        currentNetwork: action.payload.network,
      };
    }
    case Types.GETNETWORKS: {
      return {
        ...state,
        networks: action.payload.networks,
      };
    }
    case Types.GETCOLLECTOR: {
      return {
        ...state,
        currentCollector: action.payload.collector,
      };
    }
    case Types.GETINTEGRATIONS: {
      return {
        ...state,
        integrations: action.payload.integrations,
      };
    }
    case Types.GETROOTINTEGRATIONS: {
      return {
        ...state,
        rootIntegrations: action.payload.rootIntegrations,
      };
    }
    case Types.CONNECTNEWINTEGRATION: {
      return {
        ...state,
        newIntegration: action.payload.newIntegration,
      };
    }
    default: {
      return state;
    }
  }
};

export const IntegrationsContext = createContext<IntegrationsContextType | null>(null);

type IntegrationsProviderProps = {
  children: React.ReactNode;
};

export function IntegrationsProvider({ children }: IntegrationsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getServers: IntegrationsContextType['getServers'] = useCallback(
    async (context: string[]) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.get('/api/admin/device/list', {
          params: {
            contexts: context.join(','),
          },
        });

        dispatch({
          type: Types.GETSERVERS,
          payload: {
            servers: response.data.Devices,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const getServer: IntegrationsContextType['getServer'] = useCallback(
    async (id: string, context: string) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.get('/api/admin/device', {
          params: {
            id,
            context,
          },
        });

        dispatch({
          type: Types.GETSERVER,
          payload: {
            server: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const authorizeServer: IntegrationsContextType['authorizeServer'] = useCallback(
    async (id: string, context: string[], authorize: boolean) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.post('/api/admin/device/authorize', {
          id,
          contexts: context,
          authorize,
        });

        dispatch({
          type: Types.GETSERVER,
          payload: {
            server: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  // Network

  const getNetworks: IntegrationsContextType['getNetworks'] = useCallback(
    async (context: string[]) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.get('/api/admin/network/list', {
          params: {
            contexts: context.join(','),
          },
        });

        dispatch({
          type: Types.GETNETWORKS,
          payload: {
            networks: response.data.Networks,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const getNetwork: IntegrationsContextType['getNetwork'] = useCallback(
    async (id: string, context: string[]) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.get('/api/admin/network', {
          params: {
            id,
            contexts: context.join(','),
          },
        });

        dispatch({
          type: Types.GETNETWORK,
          payload: {
            network: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const addNetwork: IntegrationsContextType['addNetwork'] = useCallback(
    async (alias: string, ip: string, contexts) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.post('/api/admin/network/create', {
          alias,
          contexts,
          ip,
          collector_id: 1,
        });

        dispatch({
          type: Types.GETNETWORK,
          payload: {
            network: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const authorizeNetwork: IntegrationsContextType['authorizeNetwork'] = useCallback(
    async (id: string, context: string[], authorize: boolean) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.post('/api/admin/network/authorize', {
          id,
          contexts: context,
          authorize,
        });

        dispatch({
          type: Types.GETNETWORK,
          payload: {
            network: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const scanNetwork: IntegrationsContextType['scanNetwork'] = useCallback(
    async (id: string, context: string[]) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.post('/api/admin/network/scan', {
          id,
          contexts: context,
        });

        if (response.data.Status === 400) {
          // se deu erro 400, retorna o erro
          throw new Error('Server responded with a status of 400');
        }

        dispatch({
          type: Types.GETNETWORK,
          payload: {
            network: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }

        throw error;
      }
    },
    []
  );

  const getScanStatus: IntegrationsContextType['getScanStatus'] = useCallback(
    async (id: string, context: string[]) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.get('/api/admin/network/get-scan-status', {
          params: {
            id,
            contexts: context.join(','),
          },
        });

        dispatch({
          type: Types.GETNETWORK,
          payload: {
            network: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const getCollector: IntegrationsContextType['getCollector'] = useCallback(
    async (id: string, context: string) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.get('/api/admin/collector', {
          params: {
            id,
            context,
          },
        });

        dispatch({
          type: Types.GETCOLLECTOR,
          payload: {
            collector: response.data.Collectors,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const authorizeCollector: IntegrationsContextType['authorizeCollector'] = useCallback(
    async (id: string, context: string[], authorize: boolean) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.post('/api/admin/collector/authorize', {
          id,
          contexts: context,
          authorize,
        });

        dispatch({
          type: Types.GETCOLLECTOR,
          payload: {
            collector: response.data.Collectors,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  const getIntegrationsByContext: IntegrationsContextType['getIntegrationsByContext'] = useCallback(
    async (contexts: string[]) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.get('/v1/integrations/', {
          params: {
            contexts: contexts.join(','),
          },
        });

        dispatch({
          type: Types.GETINTEGRATIONS,
          payload: {
            integrations: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
        if (DEV_MODE) {
          dispatch({
            type: Types.GETINTEGRATIONS,
            payload: {
              integrations: mockIntegrations,
            },
          });
        }
      }
    },
    []
  );

  const getRootIntegrationsByContext: IntegrationsContextType['getRootIntegrationsByContext'] =
    useCallback(async (contexts: string[]) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.get('/v1/integrations/root', {
          params: {
            contexts: contexts.join(','),
          },
        });

        dispatch({
          type: Types.GETROOTINTEGRATIONS,
          payload: {
            rootIntegrations: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
        if (DEV_MODE) {
          dispatch({
            type: Types.GETROOTINTEGRATIONS,
            payload: {
              rootIntegrations: mockIntegrations,
            },
          });
        }
      }
    }, []);

  const addIntegration: IntegrationsContextType['addIntegration'] = useCallback(
    async (
      context: string,
      name: string,
      description: string,
      fqdn: string,
      user: string,
      password: string,
      snmpCommunity: string,
      SNPMPassphrase: string,
      integrationRootId: string
    ) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        const response = await axios.post(
          '/v1/integrations/',
          {
            name: name,
            description: description,
            integration_root_id: integrationRootId,
            source_ip: fqdn,
            auth_username: user,
            auth_password: password,
            snmp_community: snmpCommunity,
            snmp_passphrase: SNPMPassphrase,
            snmp_version: '3', // Hardcoded for now
            snmp_port: '161', // Hardcoded for now
          },
          {
            params: {
              contexts: context,
            },
          }
        );

        console.log(response);

        dispatch({
          type: Types.CONNECTNEWINTEGRATION,
          payload: {
            newIntegration: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    []
  );

  // Dava pra migrar pra redux store, mas acho melhor fazer isso quando todas as telas estiverem bem definidas, ainda vai ter muito refactor
  const setSelectedIntegration: IntegrationsContextType['setSelectedIntegration'] = useCallback(
    async (newIntegration: IntegrationsType) => {
      try {
        dispatch({
          type: Types.CONNECTNEWINTEGRATION,
          payload: {
            newIntegration,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const attachIntegration: IntegrationsContextType['attachIntegration'] = useCallback(
    async (newIntegrationId: string, endpointId: string, contexts: string[]) => {
      try {
        if (!isLoggedIn()) {
          return;
        }

        await axios.put(
          `/v1/devices/integration/${endpointId}`,
          {
            integration_id: newIntegrationId,
          },
          {
            params: {
              contexts: contexts.join(','),
            },
          }
        );

        dispatch({
          type: Types.CONNECTNEWINTEGRATION,
          payload: {
            newIntegration: null, //Motivo de pq no futuro mandar isso pro redux
          },
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const memorizedValue: IntegrationsContextType = useMemo(() => {
    const value: IntegrationsContextType = {
      getServer,
      getServers,
      authorizeServer,
      addNetwork,
      getNetwork,
      getNetworks,
      authorizeNetwork,
      scanNetwork,
      getScanStatus,
      getCollector,
      authorizeCollector,
      getIntegrationsByContext,
      getRootIntegrationsByContext,
      addIntegration,
      setSelectedIntegration,
      attachIntegration,
      ...state,
    };
    return value;
  }, [
    state,
    attachIntegration,
    getServer,
    getServers,
    authorizeServer,
    addNetwork,
    getNetwork,
    getNetworks,
    authorizeNetwork,
    scanNetwork,
    getScanStatus,
    getCollector,
    authorizeCollector,
    getIntegrationsByContext,
    getRootIntegrationsByContext,
    addIntegration,
    setSelectedIntegration,
  ]);

  return (
    <IntegrationsContext.Provider value={memorizedValue}>{children}</IntegrationsContext.Provider>
  );
}
