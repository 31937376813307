// @mui
import { Box, Link, Stack, Typography, Breadcrumbs } from '@mui/material';
//
import { CustomBreadcrumbsProps } from './types';

import LinkItem from './LinkItem';
import Iconify from '../iconify/Iconify';
import { useSelector } from '../../redux/store';
import { IBreadcrumb } from '../../redux/slices/header';

// ----------------------------------------------------------------------

export default function CustomBreadcrumbsHeader({
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  ...other
}: CustomBreadcrumbsProps) {
  const links = useSelector<IBreadcrumb[]>((state: any) => state.header.breadcrumbs);
  const lastLink = links[links.length - 1]?.name;

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          {/* HEADING */}
          {heading && (
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
          )}

          {/* BREADCRUMBS */}
          {!!links.length && (
            <Breadcrumbs separator={<Separator />} {...other}>
              {links.map((link) => (
                <LinkItem
                  key={link.name || ''}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                />
              ))}
            </Breadcrumbs>
          )}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return <Iconify icon="mdi:chevron-right" width={16} height={16} sx={{ my: -0.5, mx: 1 }} />;
}
