import React from 'react';
import { Container, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { HEADER } from '../../config-global';
import useOffSetTop from '../../hooks/useOffSetTop';

import Header from './Header';

export function CompactLayout({ children }: { children?: React.ReactNode }) {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Header isOffset={isOffset} />

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: '483px',
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children ? children : <Outlet />}
        </Stack>
      </Container>
    </>
  );
}
