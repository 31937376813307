// @mui

import { AppBar, IconButton, Stack, Toolbar } from '@mui/material';
import { FEATURES, HEADER, NAV } from '../../../config-global';

import AccountPopover from './AccountPopover';
import { ContextPopover } from './ContextPopover';
import CustomBreadcrumbsHeader from '../../../components/custom-breadcrumbs-header';
import Iconify from '../../../components/iconify';
import { LanguagePopover } from './LanguagePopover';
import Logo from '../../../components/logo';
import { NotificationsPopover } from './NotificationsPopover';
import { ThemeSwitch } from './ThemeSwitch';
import { bgBlur } from '../../../utils/cssStyles';
import { useLocation } from 'react-router';
import useResponsive from '../../../hooks/useResponsive';
import { useSettingsContext } from '../../../components/settings';
import { useTheme } from '@mui/material/styles';

// utils

// hooks

// config

// components

//

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
  disableSelectContext?: boolean;
};

export function Header({ onOpenNav, disableSelectContext = false }: Props) {
  const theme = useTheme();
  const { search } = useLocation();
  const { themeLayout } = useSettingsContext();

  const showThemeToggle =
    FEATURES.DARK_MODE || search.includes('dark') || theme.palette.mode === 'dark';
  const isNavHorizontal = themeLayout === 'horizontal';
  const isSmallScreen = useResponsive('down', 'md');
  const isNavMini = themeLayout === 'mini';
  const isDesktop = useResponsive('up', 'lg');

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.paper,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar sx={{ height: 1, px: { lg: 5 } }}>
        {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {!isSmallScreen && <CustomBreadcrumbsHeader />}
        </Stack>
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <ContextPopover disableSelectContext={disableSelectContext} />
          {!isSmallScreen && (
            <>
              {showThemeToggle && <ThemeSwitch />}
              <LanguagePopover />
              <NotificationsPopover />
            </>
          )}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
