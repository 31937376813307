import { Stack, Box } from '@mui/material';
import { LanguagePopover } from '../dashboard/header/LanguagePopover';
import { Outlet } from 'react-router-dom';
import { LoginTheme } from 'src/pages/auth/LoginTheme';

export function LoginLayoutCenter({ children }: Readonly<{ children?: React.ReactNode }>) {
  return (
    <LoginTheme>
      <Box
        sx={{
          minWidth: '100%',
          minHeight: '100%',
          display: 'flex',
          position: 'relative',
          backgroundImage: `url('/assets/background/LOGIN-MYRMEX-tamandua2.png')`,
          boxShadow: 'inset 0 0 0 1000px rgba(0, 3, 23, .6)',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'right',
          backgroundPositionY: 'center',
          backgroundSize: 'cover',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          height="100%"
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: { maxWidth: '30vw' },
          })}
        >
          <Stack
            sx={{
              display: 'flex',
              position: 'absolute',
              top: '10px',
              right: '120px',
            }}
          >
            <LanguagePopover />
          </Stack>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {children ? children : <Outlet />}
          </Box>
        </Box>
      </Box>
    </LoginTheme>
  );
}
