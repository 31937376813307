import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Result, resultAsync } from './result';

// needs to be on separate module to enable mock on jest tests

/**
 * Typing for Axios req-res
 *
 * @deprecated use `resultAsync<AxiosResponse<ResponseBody, RequestBody>, Error>(axios.get(url, data, config))`
 */
export function wrapAxios<RequestBody, ResponseBody, Error>(
  fn: (
    url: string,
    data?: RequestBody,
    config?: AxiosRequestConfig<RequestBody>
  ) => Promise<AxiosResponse<ResponseBody, RequestBody>>
): (
  url: string,
  data?: RequestBody,
  config?: AxiosRequestConfig<RequestBody>
) => Promise<Result<AxiosResponse<ResponseBody, RequestBody>, Error>> {
  return (url: string, data?: RequestBody, config?: AxiosRequestConfig<RequestBody>) =>
    resultAsync(fn(url, data, config));
}

export interface GenericSuccessResponse {
  status: 200;
  message: 'success';
}

export interface GenericErrorResponse {
  status: number;
  message: string;
  error?: string;
}
