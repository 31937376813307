import { Suspense, createContext, lazy, useMemo } from "react";

import { AuthContextType, AuthProviderProps } from "./types";
import { MICROSOFT_API_CLIENT_ID } from "../../config-global";

export const MicrosoftAuthContext = createContext<AuthContextType | null>(null);
const microsoftAuthEnabled = MICROSOFT_API_CLIENT_ID?.length > 10;

function MicrosoftAuthDisabled({ children }: Readonly<AuthProviderProps>) {
  const context = useMemo<AuthContextType>(() => ({
    login: () => { },
    enabled: false,
  }), []);
  return (
    <MicrosoftAuthContext.Provider value={context}>
      {children}
    </MicrosoftAuthContext.Provider>
  );
}

const MicrosoftAuth = lazy(() => import('./MicrosoftAuthContext'));

export function MicrosoftAuthContextProvider({ children, onLogin }: Readonly<AuthProviderProps>) {
  if (!microsoftAuthEnabled) {
    return (
      <MicrosoftAuthDisabled>
        {children}
      </MicrosoftAuthDisabled>
    );
  }
  return (
    <Suspense fallback={<MicrosoftAuthDisabled>{children}</MicrosoftAuthDisabled>}>
      <MicrosoftAuth MicrosoftAuthContext={MicrosoftAuthContext} onLogin={onLogin}>
        {children}
      </MicrosoftAuth>
    </Suspense>
  );
}
